import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import firebase from 'firebase/app';
import 'firebase/auth';
import useLatest from 'use-latest';

import {CustomAnalyticsEvent, PersonnelStatus, User, UserData} from '../types';
import userName from '../utils/userName';
import reportSnapshotError from '../utils/reportSnapshotError';
import noop from '../utils/noop';
import makeRecord from '../utils/makeRecord';
import useAnalytics from '../data/useAnalytics';
import {useTidio} from '../data/useTidio';

export interface LoginContext {
  user?: User;
  fbUser?: firebase.User;
  valid: boolean;
  loading: boolean;
  requestingPerm: boolean;
  logout: () => Promise<void> | void;
}

export const UserContext = createContext<LoginContext>({
  valid: false,
  loading: true,
  requestingPerm: false,
  logout: noop,
});

export const useLogin = () => useContext(UserContext);

export const UserProvider = ({children}: {children: React.ReactNode}) => {
  const [loginContext, setLoginContext] = useState<LoginContext>({
    valid: false,
    loading: true,
    requestingPerm: false,
    logout: noop,
  });
  const latestContext = useLatest(loginContext);
  const status = useRef<{loggingOut: boolean}>({loggingOut: false});
  const analyticsInst = useAnalytics();
  const logout = useCallback(async () => {
    if (latestContext.current?.user) {
      status.current.loggingOut = true;
      const {user} = latestContext.current;
      const waitQueue: Promise<any>[] = [];
      if (
        firebase.messaging.isSupported() &&
        Notification.permission === 'granted'
      ) {
        const token = await firebase.messaging().getToken();
        if (token && user.fcmTokens && user.fcmTokens.includes(token)) {
          waitQueue.push(
            user.ref.update({
              fcmTokens: firebase.firestore.FieldValue.arrayRemove(token),
            }),
          );
        }
      }
      if (user.personnel && user.personnel.length > 0) {
        const batch = user.ref.firestore.batch();
        user.personnel.forEach((p) => {
          batch.update(p, {
            status: PersonnelStatus.away,
          });
          batch.update(p.parent!.parent!, {
            availPersonnel: firebase.firestore.FieldValue.increment(-1),
          });
        });
        waitQueue.push(batch.commit());
      }
      if (waitQueue.length > 0) {
        try {
          await Promise.all(waitQueue);
        } catch (e) {
          console.error(e);
        }
      }

      await firebase.auth().signOut();
      status.current.loggingOut = false;
    }
  }, [loginContext]);

  useEffect(() => {
    let unsubStore: () => void | undefined;
    const unsubState = firebase.auth().onAuthStateChanged((fbUser) => {
      if (unsubStore != null) {
        unsubStore();
      }
      if (fbUser == null) {
        setLoginContext({
          valid: false,
          loading: false,
          requestingPerm: false,
          logout: noop,
        });
        analyticsInst()?.setUserProperties(
          {
            login_via: 'none',
          },
          {global: true},
        );
        return;
      }
      const ref = (
        firebase
          .firestore()
          .collection(
            'users',
          ) as firebase.firestore.CollectionReference<UserData>
      ).doc(fbUser.uid);
      unsubStore = ref.onSnapshot((storeUser) => {
        const user = makeRecord(storeUser);

        const update: {[key: string]: any} = {};
        const lastLogin = fbUser.metadata.lastSignInTime
          ? new Date(fbUser.metadata.lastSignInTime)
          : undefined;
        if (
          lastLogin &&
          !isNaN(lastLogin.getTime()) &&
          lastLogin.getTime() > (user.last?.login?.toMillis() || 0)
        ) {
          update['last.login'] =
            firebase.firestore.Timestamp.fromDate(lastLogin);
        }
        const now = new Date();
        if (
          !user.last?.session ||
          now.getTime() - user.last.session.toMillis() >= 3600000
        ) {
          update['last.session'] = firebase.firestore.Timestamp.fromDate(now);
        }
        if (Object.values(update).length !== 0) {
          user.ref.update(update);
        } else {
          setLoginContext({
            user,
            fbUser,
            valid: fbUser.emailVerified,
            loading: false,
            requestingPerm: false,
            logout,
          });
        }
      }, reportSnapshotError(ref));
    });

    return () => {
      unsubState();
      if (unsubStore != null) {
        unsubStore();
      }
    };
  }, []);

  useEffect(() => {
    if (
      status.current.loggingOut ||
      typeof window === 'undefined' ||
      !loginContext.user ||
      !loginContext.valid ||
      !firebase.messaging.isSupported()
    ) {
      return;
    }
    // let token: string | undefined;
    (async () => {
      try {
        let perm = Notification.permission;
        if (perm !== 'granted' && perm !== 'denied') {
          setLoginContext((ctx) => ({...ctx, requestingPerm: true}));
          perm = await window.Notification.requestPermission();
          setLoginContext((ctx) => ({...ctx, requestingPerm: false}));
          const inst = analyticsInst();
          if (inst) {
            if (perm === 'granted') {
              inst.logEvent(
                CustomAnalyticsEvent.GRANT_NOTIFICATION_PERMISSION,
                {},
              );
            } else {
              inst.logEvent(
                CustomAnalyticsEvent.DENY_NOTIFICATION_PERMISSION,
                {},
              );
            }
          }
        }
        if (perm === 'granted') {
          const msg = firebase.messaging();
          const token = await msg.getToken();
          if (
            !loginContext.user!.fcmTokens ||
            !loginContext.user!.fcmTokens?.includes(token)
          ) {
            loginContext.user!.ref.update({
              fcmTokens: firebase.firestore.FieldValue.arrayUnion(token),
            });
          }
        }
      } catch (e) {
        console.error(e);
      }
    })();
  }, [loginContext.user, loginContext.valid]);

  useTidio(
    (tidioApi) => {
      const {user} = loginContext;
      if (!user) {
        return;
      }
      tidioApi.setVisitorData({
        distinct_id: user.ref.id,
        email: user.email,
        name: userName(user),
        city: user.city,
        postcode: user.zipcode,
        phone: user.phone,
        country: user.country,
      });
      tidioApi.setContactProperties({
        business: user.business,
      });
    },
    [loginContext.user],
  );

  return (
    <UserContext.Provider value={loginContext}>{children}</UserContext.Provider>
  );
};
