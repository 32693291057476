import React from 'react';
import styled from 'styled-components';
import {Link} from 'react-router-dom';
import Helmet from 'react-helmet';

import {useExhibitionContext} from '../ExhibitionContext';
import useTranslated from '../../utils/useTranslated';
import {Sizer} from '../global';
import Picture from '../Picture';
import UserWidget from '../UserWidget';
import PipeLive from '../PipeLive';
import useAdEntry from '../../data/useAdEntry';
import {AdScope} from '../../types';
import Banner from '../Banner';
import {DEVICE, PAGE_MARGIN} from '../../const';

interface PropsType {
  isHome?: boolean;
  centeredLogo?: boolean;
  userWidget?: boolean;
  onLogoClick?: (e: React.MouseEvent) => void;
}

const Linker = styled(Link)`
  max-width: 100%;
`;

const HeaderSizer = styled(Sizer)`
  padding: 0;
  justify-content: space-between;
  flex-wrap: wrap;
  @media ${DEVICE.desktop} {
    flex-wrap: nowrap;
  }
`;

const Header = styled.header<{centeredLogo?: boolean}>`
  padding: 10px 0;
  ${HeaderSizer}, ${Linker} {
    display: flex;
    align-items: center;
    ${({centeredLogo}) => (centeredLogo ? 'justify-content: center;' : '')}
  }
`;

const ExhibitionLogo = styled(Picture)`
  flex: 0 1 auto;
  max-height: 70px;
  margin-left: ${PAGE_MARGIN}px;
`;

const H1 = styled.h1`
  margin: 0 0 0 ${PAGE_MARGIN}px;
  ${ExhibitionLogo} {
    margin: 0;
  }
`;

const UserWidgetRight = styled(UserWidget)`
  align-self: flex-end;
  margin-right: ${PAGE_MARGIN}px;
  @media ${DEVICE.desktop} {
    order: 1;
  }
`;

const HeaderBanner = styled(Banner)`
  margin: 10px auto 0;
  @media (min-width: 520px) {
    padding: 0 100px;
  }
  @media ${DEVICE.tablet} {
    padding: 0 70px;
  }
  @media ${DEVICE.desktop} {
    padding: 0 50px;
  }
`;

function parseHtml(str?: string): React.ReactElement[] {
  // stop if str is empty
  if (!str?.length) {
    return [];
  }

  // an array of React components
  const res: React.ReactElement[] = [];

  // each tag must be on its own line
  str.split(/\n/).forEach((node, i) => {
    // extrapolate node type
    const nodeType = /<([^ ]+)/.exec(str);
    if (!nodeType) {
      return;
    }

    // container for props
    const props: {[key: string]: any} = {
      key: i,
    };

    // match attr="value" pattern
    // store props
    node.match(/[a-z][a-z0-9\-]*="[^"]+"/g)?.forEach((attr) => {
      const matches = /^([a-z][a-z0-9\-]*)="([^"]+)"$/.exec(attr)!;
      props[matches[1]] = matches[2];
    });

    // create and save the component
    res.push(React.createElement(nodeType[1], props));
  });

  // return the array of components
  return res;
}

const ExhibitionHeader = ({
  isHome = false,
  userWidget = true,
  centeredLogo,
  onLogoClick,
}: PropsType) => {
  const data = useExhibitionContext()!;
  const strings = useTranslated(data)!;
  const ad = useAdEntry(AdScope.header);

  let logo = (
    <Linker to={data.baseUrl} onClick={onLogoClick}>
      <PipeLive theme={data.theme}>
        <ExhibitionLogo alt={strings.name} picture={data.logo} />
      </PipeLive>
    </Linker>
  );

  if (isHome) {
    logo = <H1>{logo}</H1>;
  }

  return (
    <Header centeredLogo={centeredLogo}>
      <Helmet>
        <title>{strings.name}</title>
        {parseHtml(data.customHead)}
      </Helmet>
      <HeaderSizer>
        {logo}
        {userWidget && <UserWidgetRight />}
        {ad && <HeaderBanner ad={ad} cutoff="tablet" />}
      </HeaderSizer>
    </Header>
  );
};

export default ExhibitionHeader;
