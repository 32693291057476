import React, {useEffect} from 'react';
import {useLocalStorage} from '../data/useLocalStorage';
import {useTranslation} from 'react-i18next';

import SimpleFloatingMessage from './SimpleFloatingMessage';
import {useLogin} from '../contexts/UserContext';

enum State {
  opened,
  closeable,
  closed,
}

const AcceptBrowserNotifications = () => {
  const login = useLogin();
  const [state, setState] = useLocalStorage<State>(
    'notification',
    State.opened,
    true,
  );
  const {t} = useTranslation();

  useEffect(() => {
    if (state !== State.opened) {
      return;
    }
    let timeout: NodeJS.Timeout | null = setTimeout(() => {
      timeout = null;
      setState(State.closeable);
    }, 10000);
    return () => {
      if (timeout != null) {
        clearTimeout(timeout);
      }
    };
  }, [state]);

  if (state === State.closed) {
    return null;
  }

  return (
    <SimpleFloatingMessage
      closeable={state !== State.opened}
      onRequestClose={() => setState(State.closed)}>
      <strong>{t('notifications_msg.welcome', {user: login.user})}</strong>
      <br />
      {t('notifications_msg.body')}
    </SimpleFloatingMessage>
  );
};
export default AcceptBrowserNotifications;
