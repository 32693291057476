import type firebase from 'firebase/app';
import {UserData, UserEditableData, UserRole} from './user';
import {ExhibitorData} from './exhibitor';
import {UniversalDocRef} from './common';

export enum InvitationStatus {
  new = 'new',
  sent = 'sent',
  used = 'used',
  cancelled = 'cancelled',
}

/**
 * Collection `/invitations/<invitationId>`
 */
export type InvitationData<
  R extends UniversalDocRef<ExhibitorData> = firebase.firestore.DocumentReference<ExhibitorData>,
> = UserEditableData & {
  fullName?: string;
  createdat: firebase.firestore.Timestamp | firebase.firestore.FieldValue;
  usedat?: firebase.firestore.Timestamp;
  sentby?: firebase.firestore.DocumentReference<UserData>;
  invitedExhibition: string;
  status: InvitationStatus;
} & (
    | {
        invitedRole: UserRole.admin | UserRole.visitor;
      }
    | {
        invitedRole: UserRole.exhibitor | UserRole.personnel;
        invitedExhibitor: R;
      }
  );

export type Invitation = InvitationData & {
  ref: firebase.firestore.DocumentReference<InvitationData>;
};
