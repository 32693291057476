import * as React from 'react';

function EnglishFlag(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 13" {...props}>
      <g fill="none" fillRule="evenodd">
        <path fill="#FFF" d="M0 12.519h19.743V.772H0z" />
        <path
          fill="#BD0034"
          d="M19.293.773l-7.438 4.246h1.364l6.524-3.725V.773zm-6.382 7.735l6.832 3.899v-.78l-5.467-3.12zM0 1.666L5.844 5.02h1.365L0 .886zm6.9 6.842L0 12.45v.068h1.247l7.019-4.011z"
        />
        <path
          fill="#1A237B"
          d="M11.48.773V4.72L18.391.773zm-9.938 0L8.427 4.72V.773zM15.053 5.02h4.69V2.324zm0 3.488l4.69 2.665V8.508zm-13.12 4.011h6.494V8.806zm9.547 0h6.514l-6.515-3.713zM0 8.508v2.785l4.852-2.785zM0 5.02h4.852L0 2.23z"
        />
        <path
          fill="#BD0034"
          d="M9.038.773v4.944H0v2.094h9.038v4.708h1.83V7.81h8.875V5.717h-8.874V.773z"
        />
      </g>
    </svg>
  );
}

export default EnglishFlag;
