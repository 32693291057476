import {DependencyList} from 'react';
import firebase from 'firebase/app';
import useFirestoreSnap from './useFirestoreSnap';
import makeRecord from '../utils/makeRecord';
import {RecordRef} from '../types';

const useFirestoreRecord = <D extends object>(
  ref:
    | firebase.firestore.DocumentReference<D>
    | string
    | undefined
    | null
    | (() => firebase.firestore.DocumentReference<D> | undefined | null),
  deps: DependencyList,
  live: boolean = false,
): [RecordRef<D> | undefined, boolean] =>
  useFirestoreSnap(ref, (snap) => makeRecord(snap), deps, live);

export default useFirestoreRecord;
