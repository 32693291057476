import type firebase from 'firebase/app';
import {Translated} from './translated';
import {
  URLString,
  Color,
  PictureType,
  PublishStatus,
  ThemeType,
  UniversalDocRef,
  RecordRef,
} from './common';

export type ExhibitionID = string;

export enum ExhibitionAvailability {
  preview,
  open,
  ended,
  closed,
}

export enum LoginType {
  google = 'google',
  facebook = 'facebook',
  linkedin = 'linkedin',
  eventbrite = 'eventbrite',
}

export enum ExhibitorsSort {
  alpha = 'alpha',
  random = 'random',
}

export enum ExhibitionType {
  b2b = 'b2b',
  b2c = 'b2c',
}

interface ExhibitionThemeOptions {
  block: {
    height?: number; // ratio, ie. 1 is for a square
    background?: string; // CSS background string
    fontColor?: Color;
    fontSize?: number;
  };
}

export interface ExhibitionTheme {
  logo: PictureType; // logo url
  backgroundColor: Color; // html valid color
  externalBackgroundColor: Color; // html valid color
  externalBackgroundImage?: URLString; // image url
  textColor: Color; // html valid color
  accentColor: Color; // html valid color
  theme: ThemeType;
  blocksPerLine: number; // number of blocks per line
  options?: ExhibitionThemeOptions;
}

interface ExhibitionOptions extends ExhibitionThemeOptions {
  logins?: {
    [type in LoginType]?: boolean;
  };
  sort?: ExhibitorsSort;
  type?: ExhibitionType;
  mynetCat?: string;
  mynetCourseCat?: string;
}

/**
 * Collection `/exhibitions/<exhibitionId>`
 */
export interface ExhibitionData
  extends ExhibitionTheme,
    Translated<{
      name: string;
      privacy?: {text: string; title: string};
      sectors?: string[];
      personnelSectors?: string[];
    }> {
  status: PublishStatus;
  ids?: {[key: string]: number | string};
  domain: string;

  available_from: firebase.firestore.Timestamp;
  available_to: firebase.firestore.Timestamp;
  start_at: firebase.firestore.Timestamp;
  end_at: firebase.firestore.Timestamp;
  website: string;
  customHead?: string;
  options?: ExhibitionOptions;
}

export type ExhibitionConcrete<
  R extends UniversalDocRef<ExhibitionData> = firebase.firestore.DocumentReference<ExhibitionData>,
> = RecordRef<ExhibitionData, R>;

export interface Exhibition<
  R extends UniversalDocRef<ExhibitionData> = firebase.firestore.DocumentReference<ExhibitionData>,
> extends ExhibitionConcrete<R> {
  baseUrl: URLString;
  availability: ExhibitionAvailability;
  showExhibitors: boolean;
}
