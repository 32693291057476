export default function userName(
  user: {
    firstName?: string;
    lastName?: string;
    business?: string;
  },
  business: boolean = false,
): string {
  const name = `${user.firstName || ''} ${user.lastName || ''}`.trim();
  if (business && user.business) {
    return `${name} (${user.business})`;
  }
  return name;
}
