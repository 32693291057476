import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
import 'firebase/remote-config';
import 'firebase/storage';
import 'firebase/functions';
import 'firebase/messaging';
import 'firebase/analytics';

const firebaseConfig = {
  apiKey: process.env.RAZZLE_FB_APIKEY,
  authDomain: process.env.RAZZLE_FB_AUTHDOMAIN,
  databaseURL: process.env.RAZZLE_FB_DATABASEURL,
  projectId: process.env.RAZZLE_FB_PROJECTID,
  storageBucket: process.env.RAZZLE_FB_STORAGEBUCKET,
  messagingSenderId: process.env.RAZZLE_FB_MESSAGINGSENDERID,
  appId: process.env.RAZZLE_FB_APPID,
  measurementId: process.env.RAZZLE_FB_MEASUREMENTID,
};

const app = firebase.initializeApp(firebaseConfig);

export default app;
