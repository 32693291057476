import firebase from 'firebase/app';
import {useCookies} from 'react-cookie';

import {ACCEPTS_COOKIES_NAME} from '../const';

const nullFunc = () => undefined;

function useAnalytics(): () => firebase.analytics.Analytics | undefined {
  const [cookies] = useCookies([ACCEPTS_COOKIES_NAME]);
  return cookies[ACCEPTS_COOKIES_NAME] === 'true'
    ? () => firebase.analytics()
    : nullFunc;
}

export default useAnalytics;
