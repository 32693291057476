import {useEffect, useState} from 'react';
import firebase from 'firebase/app';

import {useExhibitionContext} from '../components/ExhibitionContext';
import {
  AdEntry,
  AdScope,
  CreatableRecord,
  Event,
  EventData,
  Falsy,
} from '../types';
import makeRecord from '../utils/makeRecord';
import {isBefore} from 'date-fns';

const useAdEntry = (
  scope: AdScope | null,
  {event}: {event?: Event | CreatableRecord<EventData> | null | false} = {},
): AdEntry | undefined => {
  const exhibition = useExhibitionContext();
  const [ad, setAd] = useState<AdEntry | undefined>();
  useEffect(() => {
    if (!exhibition || !scope || event === null || event === false) {
      return;
    }
    (async () => {
      let query = (
        exhibition.ref.collection(
          'ads',
        ) as firebase.firestore.CollectionReference<AdEntry>
      )
        .where('enabled', '==', true)
        .where('scope', '==', scope);
      if (event?.ref != null) {
        query = query.where('events', 'array-contains', event.ref);
      }
      const list = await query
        .where('start_at', '<=', firebase.firestore.Timestamp.now())
        .get();
      if (!list.empty) {
        const find = list.docs.find((d) =>
          isBefore(new Date(), d.data()!.end_at.toDate()),
        );
        if (find != null) {
          setAd(makeRecord(find));
        }
      }
    })();
  }, [scope, exhibition]);

  return ad;
};

export default useAdEntry;
