import React from 'react';
import {Link, useLocation} from 'react-router-dom';
import styled from 'styled-components';
import {useTranslation} from 'react-i18next';

import {useExhibitionContext} from '../components/ExhibitionContext';
import PlainButton from '../components/PlainButton';
import SmartPageChrome from '../components/SmartPageChrome';
import {DEVICE, FONT_BOLD} from '../const';

const Page = styled.div`
  text-align: center;
`;

const Title = styled.h1`
  font-size: 24px;
  font-weight: ${FONT_BOLD};
  margin: 2em 0 0.5em;

  @media ${DEVICE.tablet} {
    font-size: 36px;
  }
`;

const NotFound = () => {
  const exhibition = useExhibitionContext();
  const {t} = useTranslation();
  const location = useLocation();
  return (
    <SmartPageChrome>
      <Page>
        <Title>{t('notfound.title')}</Title>
        {!exhibition ||
        location.pathname !=
          exhibition.baseUrl.replace(/^[^:]+:\/\/[^\/]+/, '') ? (
          <PlainButton as={Link} to={exhibition?.baseUrl || '/'}>
            {t('common.backtohome')}
          </PlainButton>
        ) : (
          exhibition &&
          exhibition.website && (
            <PlainButton as="a" href={exhibition.website}>
              {t('notfound.go_to_website')}
            </PlainButton>
          )
        )}
      </Page>
    </SmartPageChrome>
  );
};

export default NotFound;
