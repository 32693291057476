import {Translated} from './translated';
import type firebase from 'firebase/app';
import type {firestore as adminFirestore} from 'firebase-admin';

export type URLString = string;
export type VideoURL = URLString;
export type Color = string;
export type Falsy = false | 0 | '' | null | undefined;
export type ThemeType = 'light' | 'dark';

export enum VideoService {
  youtube,
  vimeo,
}

export interface PictureType {
  source: URLString;
  sizes?: {
    width: number;
    height: number;
    density: number;
    source: URLString;
  }[];
}

export enum PublishStatus {
  published = 'published',
  private = 'private',
  hidden = 'hidden',
}

export interface MenuEntry extends Translated<{name: string}> {
  url: URLString | ((baseUrl: URLString) => URLString);
}

export type CreatableRecord<Data> = Data & {
  ref: firebase.firestore.DocumentReference<Data> | null;
};

export interface Choice {
  id: string;
  name: string;
}

export interface DbChoice extends Choice {
  path: string;
}

export interface CountData extends CountDataWrite {
  count: number;
  lastupdate: firebase.firestore.Timestamp;
}

export interface CountDataWrite {
  count: number | firebase.firestore.FieldValue;
  lastupdate: firebase.firestore.Timestamp | firebase.firestore.FieldValue;
}

export interface AddressData {
  address?: string;
  city?: string;
  zipcode?: string;
  country?: string; // 2-letter ISO 3166 eg. IT
  state?: string;
  phone?: string;
  email?: string;
}

export interface CountryListValue {
  c: string;
  n: string;
}

export interface CountryListDoc {
  cacheValue: CountryListValue[];
}

export interface StateList {
  [code: string]: string;
}

export interface StateListDoc {
  states: StateList;
}

export type UniversalDocRef<T extends object> =
  | firebase.firestore.DocumentReference<T>
  | adminFirestore.DocumentReference<T>;

export interface WithRef<
  T extends object,
  R extends UniversalDocRef<T> = firebase.firestore.DocumentReference<T>,
> {
  ref: R;
}

export type RecordRef<
  T extends object,
  R extends UniversalDocRef<T> = firebase.firestore.DocumentReference<T>,
> = T & WithRef<T, R>;

export type DehydratedRecordRef<T extends object> = T & {
  ref: string;
};

export type FirestoreUpdate<T extends {[key: string]: any}> = {
  [key in keyof T]?: T[key] | firebase.firestore.FieldValue;
};
