import React from 'react';
import Banner from 'react-cookie-banner';
import {Link} from 'react-router-dom';
import styled from 'styled-components';
import {useTranslation} from 'react-i18next';

import {ACCEPTS_COOKIES_NAME, BLACK, DEVICE, FONT_THIN, WHITE} from '../const';
import {useExhibitionContext} from './ExhibitionContext';

const StyledBanner = styled(Banner)`
  color: ${WHITE};
  background: ${BLACK};
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: auto;
  padding: 16px;
  box-sizing: border-box;
  display: flex;
  align-items: flex-end;
  z-index: 1000000000;

  .cookie-message {
    font-weight: ${FONT_THIN};
    line-height: 1.5;
    flex: 1 1 100%;
    text-align: center;
  }
  a,
  .button-close {
    color: inherit;
  }
  .button-close {
    -webkit-appearance: none;
    border-radius: 5px;
    border: none;
    padding: 5px 15px;
    position: static;
    background: #00a99d;
    flex: 0 0 auto;
    margin-left: 20px;
    text-transform: uppercase;
  }

  @media ${DEVICE.tablet} {
    align-items: center;
    justify-content: center;
    .cookie-message {
      flex: 0 1 auto;
    }
  }
`;

const CookieBanner = () => {
  const exhibition = useExhibitionContext();
  const {t} = useTranslation();

  return typeof window !== 'undefined' ? (
    <StyledBanner
      message={t('cookiebanner.message')}
      link={
        <>
          {' '}
          {exhibition ? (
            <Link to="/privacy-policy">{t('policy.privacy')}</Link>
          ) : (
            <a href={process.env.RAZZLE_PRIVACY_POLICY}>
              {t('policy.privacy')}
            </a>
          )}
          {', '}
          <a href={process.env.RAZZLE_COOKIE_POLICY}>{t('policy.cookie')}</a>
        </>
      }
      disableStyle={true}
      buttonMessage={t('common.ok')}
      dismissOnScroll={false}
      cookie={ACCEPTS_COOKIES_NAME}
    />
  ) : null;
};

export default CookieBanner;
