let supported: boolean | null = null;
const supportsPassive = (): boolean => {
  if (supported == null) {
    supported = false;
    // tslint:disable-next-line: no-empty
    document.createElement('div').addEventListener('test', () => {}, {
      get passive() {
        supported = true;
        return false;
      },
    });
  }
  return supported;
};

export default supportsPassive;
