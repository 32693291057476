import type firebase from 'firebase/app';
import {
  UserData,
  UserRole,
  ExhibitionData,
  Falsy,
  UniversalDocRef,
  ExhibitorData,
  Exhibition,
} from '../types';

export default function isExhibitionAdmin<
  E extends UniversalDocRef<ExhibitorData> = firebase.firestore.DocumentReference<ExhibitorData>,
>(
  user: UserData<E> | undefined,
  exhibition: Exhibition | UniversalDocRef<ExhibitionData> | Falsy,
): boolean {
  if (!exhibition) {
    return false;
  }
  const exhibitionId = exhibition.hasOwnProperty('ref')
    ? (exhibition as Exhibition).ref.id
    : (exhibition as UniversalDocRef<ExhibitionData>).id;
  if (
    user == null ||
    ((user.role == null || user.role === UserRole.visitor) &&
      (user.roles == null || !user.roles[exhibitionId]))
  ) {
    return false;
  }
  if (user.role === UserRole.admin) {
    return true;
  }
  const role = user.roles![exhibitionId];
  return role.role === UserRole.admin;
}
