export default function isEmpty<T>(
  value: T | null | undefined,
  html: boolean = false,
): boolean {
  return (
    value == null ||
    ((typeof value === 'string' || Array.isArray(value)) &&
      value.length === 0) ||
    (html && typeof value === 'string' && value.trim() === '<p></p>') ||
    (typeof value === 'object' && Object.keys(value).length === 0)
  );
}
