import React, {useMemo} from 'react';
import styled from 'styled-components';

import {Sizer} from '../global';
import {WHITE, FONT_MEDIUM, DEVICE, BLACK} from '../../const';
import FdpLogo from '../../assets/svg/fdp_logo';
import useTranslated from '../../utils/useTranslated';
import getLinks from '../../utils/getLinks';
import cutSlash from '../../utils/cutSlash';
import {useExhibitionContext} from '../ExhibitionContext';
import useSocials from '../../data/useSocials';
import TopLanguageSelector from './TopLanguageSelector';

const TopHeader = styled.div`
  flex: 0 0 auto;
  background: ${WHITE};
  ${Sizer} {
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media ${DEVICE.tablet} {
      height: 40px;
    }
  }
`;

const TopLogo = styled(FdpLogo)`
  @media ${DEVICE.tablet} {
    width: 117px;
    height: 15px;
  }
`;

const TopMenu = styled.ul`
  display: none;
  @media ${DEVICE.tablet} {
    &,
    li {
      list-style: none;
      padding: 0;
    }
    flex: 0 1 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: ${FONT_MEDIUM};
  }
`;

const CenterMenu = styled(TopMenu)`
  @media ${DEVICE.tablet} {
    margin: 0 20px 0 auto;
  }
`;

const MenuItem = styled.li`
  margin: 0;
  display: block;
  flex: 0 1 auto;
`;

const MenuLink = styled.a`
  color: ${BLACK};
  text-decoration: none;
  padding: 10px 15px;
  transition: opacity 0.4s ease-in-out;
  &:hover {
    opacity: 0.7;
  }
`;

const SocialLink = styled(MenuLink)`
  padding-left: 5px;
  padding-right: 5px;
`;

const SiteHeader = () => {
  const exhibition = useExhibitionContext();
  const socials = useSocials();
  const topMenuEntries = useMemo(
    () => getLinks(process.env.RAZZLE_TOPLINKS),
    [],
  );
  if (
    process.env.RAZZLE_FEATURES &&
    process.env.RAZZLE_FEATURES.indexOf('topbar') === -1
  ) {
    return null;
  }
  return (
    <TopHeader>
      <Sizer>
        <a href={process.env.RAZZLE_ORG_WEBSITE}>
          <TopLogo fill={BLACK} />
        </a>
        <CenterMenu>
          {topMenuEntries.map((entry) => {
            const url =
              typeof entry.url === 'string'
                ? entry.url
                : entry.url(
                    exhibition
                      ? cutSlash(exhibition.baseUrl)
                      : process.env.RAZZLE_ORG_WEBSITE!,
                  );
            return (
              <MenuItem key={url}>
                <MenuLink href={url}>{useTranslated(entry)!.name}</MenuLink>
              </MenuItem>
            );
          })}
        </CenterMenu>
        <TopMenu>
          <TopLanguageSelector />
          <MenuItem>
            {socials.map((entry) => (
              <SocialLink
                key={entry.url}
                href={entry.url}
                target="_blank"
                rel="nofollow noopener">
                <entry.Icon />
              </SocialLink>
            ))}
          </MenuItem>
        </TopMenu>
      </Sizer>
    </TopHeader>
  );
};

export default SiteHeader;
