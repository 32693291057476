import {format, Locale} from 'date-fns';
import type i18next from 'i18next';
import itDateLocale from 'date-fns/locale/it';
import enDateLocale from 'date-fns/locale/en-US';

export const dateLocales: {[key: string]: Locale} = {
  it: itDateLocale,
  en: enDateLocale,
};

function formatDate(
  date: Date | number,
  formatStr: string,
  options?: {
    localeCode?: string;
    i18n?: typeof i18next;
    locale?: Locale;
    weekStartsOn?: 0 | 1 | 2 | 3 | 4 | 5 | 6;
    firstWeekContainsDate?: number;
    useAdditionalWeekYearTokens?: boolean;
    useAdditionalDayOfYearTokens?: boolean;
  },
): string {
  const localeCode = options?.i18n
    ? options?.i18n.languages[0]
    : options?.localeCode?.replace(/-.+$/, '');
  return format(date, formatStr, {
    ...options,
    locale:
      (localeCode && dateLocales[localeCode]) ||
      options?.locale ||
      itDateLocale,
  });
}

export default formatDate;
