import React, {Fragment, useEffect, useMemo, useState} from 'react';
import firebase from 'firebase/app';
import styled from 'styled-components';

import {Exhibition, Meeting, MeetingData, MeetingStatus, User} from '../types';
import reportSnapshotError from '../utils/reportSnapshotError';
import makeRecord from '../utils/makeRecord';
import getAllDocs from '../utils/getAllDocs';
import {ChatContextType} from '../pages/exhibition/ChatContainer';
import FloatingChatWidget from './FloatingChatWidget';
import {TransparentButton} from './global';
import UserName from './UserName';
import {useTranslation} from 'react-i18next';
import {ACCENT_RED, WHITE} from '../const';

function newPaths<I extends {ref: {path: string}}>(
  newList?: I[],
  oldList?: I[],
): string[] {
  if (!newList) {
    return [];
  }
  const newId = newList.map(({ref: {path}}) => path).sort();
  if (!oldList) {
    return newId;
  }
  const oldId = oldList.map(({ref: {path}}) => path).sort();
  const res: string[] = [];
  let i = 0;
  let j = 0;
  while (i < newId.length && j < oldId.length) {
    if (newId[i] === oldId[j]) {
      ++i;
      ++j;
    } else if (newId[i] < oldId[j]) {
      res.push(newId[i]);
      ++i;
    } else {
      ++j;
    }
  }
  if (i < newId.length) {
    res.push(...newId.slice(i));
  }
  return res;
}

interface OpenedList {
  [path: string]: Date;
}

interface PropsType extends ChatContextType {
  visible: string[];
  hidden: string[];
  user: User;
  exhibition: Exhibition;
}

const List = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
`;

const Item = styled.li`
  list-style: none;
  padding: 0;
  margin: 0;
`;

const ItemButton = styled(TransparentButton)<{unread?: boolean}>`
  font-size: 14px;
  cursor: pointer;
  padding: 10px;
  ${({unread}) =>
    unread ? `background-color: ${ACCENT_RED}; color: ${WHITE};` : ''}
`;

const ChatList = ({
  visible,
  hidden,
  user,
  exhibition,
  upsertChat,
}: PropsType) => {
  const {t} = useTranslation();
  const personnelRef = user.personnel?.find((p) =>
    p.path.startsWith(exhibition.ref.path),
  );
  const [[chats, lastOpen], setChats] = useState<[Meeting[], OpenedList]>([
    [],
    {},
  ]);
  const [open, setOpen] = useState<boolean>(false);
  const cancel: (() => void)[] = [];
  useEffect(() => {
    let load = [...hidden];
    let personnelLoaded: Meeting[] | undefined;
    let hiddenLoaded: Meeting[] | undefined;
    let opened: OpenedList = {};
    if (personnelRef != null) {
      const collection = personnelRef.collection(
        'meetings',
      ) as firebase.firestore.CollectionReference<MeetingData>;
      const meetings = collection
        .where('status', '==', MeetingStatus.chat)
        .orderBy('queuedAt', 'desc');
      cancel.push(
        meetings.onSnapshot((snap) => {
          const newLoaded = snap.docs
            .filter((d) => !visible.includes(d.id))
            .map(makeRecord);
          newPaths(newLoaded, personnelLoaded).forEach((path) => {
            opened[path] = new Date();
          });
          personnelLoaded = newLoaded;
          if (hiddenLoaded != null) {
            setChats(([, o]) => [
              [...personnelLoaded!, ...hiddenLoaded!],
              {...o, ...opened},
            ]);
            opened = {};
          }
        }, reportSnapshotError(collection)),
      );
      load = load.filter((p) => !p.startsWith(personnelRef.path));
    } else {
      personnelLoaded = [];
    }
    if (load.length !== 0) {
      const db = firebase.firestore();
      cancel.push(
        getAllDocs(
          load.map(
            (p) =>
              db.doc(p) as firebase.firestore.DocumentReference<MeetingData>,
          ),
          (docs) => {
            const newHidden = docs.map(makeRecord);
            newPaths(newHidden, hiddenLoaded).forEach((path) => {
              opened[path] = new Date();
            });
            hiddenLoaded = newHidden;
            if (personnelLoaded != null) {
              setChats(([, o]) => [
                [...personnelLoaded!, ...hiddenLoaded!],
                {...o, ...opened},
              ]);
              opened = {};
            }
          },
        ),
      );
    } else {
      hiddenLoaded = [];
    }
    return () => cancel.forEach((c) => c());
  }, [personnelRef, visible, hidden]);
  const hasUnread = useMemo(
    () =>
      chats.some(
        (c) =>
          c.lastMsg &&
          (!lastOpen[c.ref.path] ||
            lastOpen[c.ref.path].getTime() < c.lastMsg.toMillis()),
      ),
    [chats, lastOpen],
  );

  if (chats.length === 0) {
    return null;
  }

  return (
    <FloatingChatWidget
      onLabelClick={() => setOpen((o) => !o)}
      onMinimizeClick={open ? () => setOpen(false) : undefined}
      label={t('chat.chats')}
      blink={!open && hasUnread}>
      {open && (
        <List>
          {chats.map((c) => (
            <Item key={c.ref.id}>
              <ItemButton
                type="button"
                onClick={() => {
                  setChats(([list, o]) => [
                    list,
                    {...o, [c.ref.id]: new Date()},
                  ]);
                  upsertChat(c.ref.path, {open: true});
                }}
                unread={
                  c.lastMsg &&
                  (!lastOpen[c.ref.path] ||
                    lastOpen[c.ref.path].getTime() < c.lastMsg.toMillis())
                }>
                {c.users.map((u, i) => (
                  <Fragment key={u.id}>
                    <UserName user={u} />
                    {i < c.users.length - 1 ? ', ' : false}
                  </Fragment>
                ))}
              </ItemButton>
            </Item>
          ))}
        </List>
      )}
    </FloatingChatWidget>
  );
};

export default ChatList;
