import * as React from 'react';

function ItalianFlag(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 12" {...props}>
      <g fill="none" fillRule="evenodd">
        <path fill="#009246" d="M0 11.531h6.581V.343H0z" />
        <path fill="#F9F9F9" d="M6.581 11.531h6.58V.343h-6.58z" />
        <path fill="#CE2B37" d="M13.162 11.531h6.582V.343h-6.582z" />
      </g>
    </svg>
  );
}

export default ItalianFlag;
