import firebase from 'firebase/app';
import {RecordRef, UniversalDocRef} from './common';
import {ExhibitorData} from './exhibitor';
import {UserData, UserPublicData} from './user';

export enum MeetingStatus {
  chat = 'chat',
  queued = 'queued',
  live = 'live',
  done = 'done',
  closed = 'closed',
}

export interface ChimeMeeting {
  ExternalMeetingId?: string;
  MediaPlacement?: any; // {[key: string]: string};
  MediaRegion?: string;
  MeetingId?: string;
}

/**
 * Collection `/exhibitions/<exhibitionId>/exhibitors/<exhibitorId>/personnel/<personnelId>/meetings/<meetingId>`
 */
export interface MeetingData<
  E extends UniversalDocRef<ExhibitorData> = firebase.firestore.DocumentReference<ExhibitorData>,
  P extends UniversalDocRef<UserData<E>> = firebase.firestore.DocumentReference<
    UserData<E>
  >,
> {
  Meeting?: ChimeMeeting;
  status: MeetingStatus;
  queuedAt: firebase.firestore.Timestamp;
  startedAt?: firebase.firestore.Timestamp;
  endedAt?: firebase.firestore.Timestamp;
  users: P[];
  userData: {[id: string]: UserPublicData};
  lastCheck?: firebase.firestore.Timestamp;
  lastMsg?: firebase.firestore.Timestamp;
}

export type Meeting = RecordRef<MeetingData>;

/**
 * Collection `/exhibitions/<exhibitionId>/exhibitors/<exhibitorId>/personnel/
 *   <personnelId>/meetings/<meetingId>/attendee/<userId>`
 */
export interface AttendeeData {
  AttendeeId?: string;
  ExternalUserId?: string;
  JoinToken?: string;
}

export type Attendee = RecordRef<AttendeeData>;
