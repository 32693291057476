import {useEffect} from 'react';
import {addMinutes, differenceInMinutes} from 'date-fns';
import firebase from 'firebase/app';

import {Personnel} from '../types';

const usePersonnelCheckUpdate = (personnel?: Personnel | null | false) => {
  useEffect(() => {
    if (!personnel) {
      return;
    }
    let timeout: NodeJS.Timeout | undefined;
    let stop = false;

    async function updateCheck() {
      if (!personnel || stop) {
        return;
      }
      let lastCheck = personnel.lastCheck?.toDate();
      if (!lastCheck) {
        lastCheck = new Date('2021-01-01T00:00:00');
      }
      const now = new Date();
      if (differenceInMinutes(now, lastCheck) >= 10) {
        await personnel.ref.update(
          'lastCheck',
          firebase.firestore.Timestamp.fromDate(now),
        );
        timeout = undefined;
        stop = true;
      } else {
        timeout = setTimeout(
          updateCheck,
          Math.max(addMinutes(lastCheck, 10).getTime() - now.getTime(), 100),
        );
      }
    }

    updateCheck();

    return () => {
      stop = true;
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [personnel]);
};

export default usePersonnelCheckUpdate;
