import {Translated, Falsy, TranslateMap} from '../types';
import i18next from 'i18next';

export default function getTranslated<T extends TranslateMap>(
  data: Translated<T> | Falsy,
  i18n: typeof i18next | string,
): T | undefined {
  if (!data || !data.lang) {
    return undefined;
  }
  const {lang} = data;
  for (const locale of typeof i18n === 'string' ? [i18n] : i18n.languages) {
    if (lang[locale]) {
      return lang[locale];
    }
  }
  return undefined;
}
