import React from 'react';
import styled from 'styled-components';

import {AdEntry} from '../types';
import {DeviceClass} from '../const';
import {HideOn, ShowOn} from './global';

interface PropsType {
  ad: AdEntry;
  className?: string;
  cutoff?: DeviceClass;
}

const Ad = styled.a`
  display: block;
  text-align: center;
  img {
    margin: 0 auto;
  }
`;

const Banner = ({ad, className, cutoff}: PropsType) => (
  <Ad
    className={className}
    href={ad.link}
    target="_blank"
    rel="nofollow noopener">
    {ad.desktop && (
      <ShowOn
        device={cutoff!}
        as="img"
        {...ad.desktop}
        alt={ad.name}
        loading="lazy"
      />
    )}
    {ad.mobile && (
      <HideOn
        device={cutoff!}
        as="img"
        {...ad.mobile}
        alt={ad.name}
        loading="lazy"
      />
    )}
  </Ad>
);

Banner.defaultProps = {cutoff: 'desktop'};

export default React.memo(Banner);
