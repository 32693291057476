import React, {ReactNode, useRef} from 'react';
import {useTranslation} from 'react-i18next';
import ScrollToBottom, {
  StateContext,
  useScrollToEnd,
} from 'react-scroll-to-bottom';
import styled from 'styled-components';

import Triangle from '../assets/svg/triangle';
import {PAGE_MARGIN, WHITE, BLACK} from '../const';
import PlainButton from './PlainButton';

interface PropsType {
  children?: ReactNode;
  lastMsgId?: string;
}

interface ScrollerPropsType extends PropsType {
  className?: string;
}

const ChatContent = styled(ScrollToBottom)`
  padding: 10px;
  height: 10em;
  background: ${WHITE};
  color: ${BLACK};
  font-size: 14px;
  .scroller {
    overflow-y: scroll;
    padding: 0 ${PAGE_MARGIN}px 0 0;
  }
  a {
    color: ${({theme: {accentColor}}) => accentColor};
  }
  .no {
    display: none;
  }
`;

const BottomButton = styled(PlainButton)`
  padding: 0;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${WHITE};
  position: absolute;
  bottom: 20px;
  right: 10px;
  background: rgba(88, 88, 90, 0.6);
  svg {
    transform: rotate(180deg);
  }
`;

const SpacedTriangle = styled(Triangle)`
  margin-left: 10px;
  transform: rotate(180deg);
`;

const NewMsgButton = styled(PlainButton)`
  position: absolute;
  top: 20px;
  left: 50%;
  transform: translate(-50%, 0);
  background: rgba(88, 88, 90, 0.6);
`;

const Content = ({children, lastMsgId}: PropsType) => {
  const scrollToEnd = useScrollToEnd();
  const lastMsg = useRef<string | undefined>();
  const {t} = useTranslation();

  return (
    <StateContext.Consumer>
      {({sticky}: {sticky: boolean}) => {
        if (sticky) {
          lastMsg.current = lastMsgId;
        }
        return (
          <>
            {lastMsgId && lastMsg.current !== lastMsgId && (
              <NewMsgButton type="button" onClick={scrollToEnd}>
                {t('chat.new_msgs')}
                <SpacedTriangle />
              </NewMsgButton>
            )}
            {children}
            {!lastMsgId ||
              (lastMsg.current === lastMsgId && (
                <BottomButton
                  type="button"
                  onClick={scrollToEnd}
                  style={{display: sticky ? 'none' : 'block'}}>
                  <Triangle />
                </BottomButton>
              ))}
          </>
        );
      }}
    </StateContext.Consumer>
  );
};

const ChatScroller = ({className, ...props}: ScrollerPropsType) => (
  <ChatContent
    scrollViewClassName="scroller"
    behavior="smooth"
    followButtonClassName="no"
    className={className}>
    <Content {...props} />
  </ChatContent>
);

export default ChatScroller;
