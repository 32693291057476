import React, {ReactNode} from 'react';
import styled, {keyframes} from 'styled-components';
import {
  DARK_GRAY,
  LIGHTER_GRAY,
  WHITE,
  FONT_MEDIUM,
  ACCENT_RED,
} from '../const';

import ChatBubbles from '../assets/svg/chat_bubbles';
import {TransparentButton} from './global';
import CloseIcon from '../assets/svg/close';
import MinimizeIcon from '../assets/svg/minimize';

interface PropsType {
  children?: ReactNode;
  label?: ReactNode;
  onCloseClick?: () => void;
  onMinimizeClick?: () => void;
  onLabelClick?: () => void;
  titleColor?: string;
  titleBackground?: string;
  blink?: boolean;
}

const Float = styled.div`
  position: relative;
  border-radius: 12px 12px 0 0;
  background: ${WHITE};
  border: 1px solid ${LIGHTER_GRAY};
  box-shadow: 1px -1px 2px rgba(0, 0, 0, 0.75);
  margin: 0 2px;
  max-width: 250px;
`;

const blinkAnimation = keyframes`
  30% {  }
  50% { background: ${ACCENT_RED}; color: ${WHITE}; }
  55% {  }
`;

const Top = styled.h2<{blink?: boolean}>`
  margin: 0;
  font-size: 1em;
  border-radius: 12px 12px 0 0;
  color: ${DARK_GRAY};
  width: 100%;
  display: flex;
  animation-name: ${blinkAnimation};
  ${({blink}) =>
    blink
      ? `
    animation-duration: 2.5s;
    animation-iteration-count: infinite;
  `
      : ''}
`;

const Label = styled(TransparentButton)`
  flex: 0 0 auto;
  padding: 13px 15px 11px;
  cursor: pointer;
  font-size: 16px;
  font-weight: ${FONT_MEDIUM};
  text-transform: none;
  margin-right: 10px;
`;

const Icon = styled(ChatBubbles)`
  vertical-align: middle;
  margin-right: 5px;
`;

const CloseArea = styled.div`
  margin-left: auto;
  display: flex;
  padding: 2px 2px 0 0;
  align-items: center;
  justify-content: flex-end;
  align-self: flex-start;
`;

const CloseButton = styled(TransparentButton)`
  cursor: pointer;
`;

const FloatingChatWidget = ({
  children,
  label,
  onCloseClick,
  onMinimizeClick,
  onLabelClick,
  titleColor,
  titleBackground,
  blink,
}: PropsType) => (
  <Float>
    <Top style={{color: titleColor, background: titleBackground}} blink={blink}>
      <Label type="button" onClick={onLabelClick}>
        <Icon
          fill="currentColor"
          stroke={DARK_GRAY}
          strokeWidth={titleColor ? 0 : 1}
        />
        {label}
      </Label>
      {(onMinimizeClick || onCloseClick) && (
        <CloseArea>
          {onMinimizeClick && (
            <CloseButton onClick={onMinimizeClick}>
              <MinimizeIcon width={12} fill={DARK_GRAY} />
            </CloseButton>
          )}
          {onCloseClick && (
            <CloseButton onClick={onCloseClick}>
              <CloseIcon width={12} fill={DARK_GRAY} />
            </CloseButton>
          )}
        </CloseArea>
      )}
    </Top>
    {children}
  </Float>
);

export default FloatingChatWidget;
