import {useEffect} from 'react';
import {useLocation} from 'react-router-dom';
import firebase from 'firebase/app';
import {useTranslation} from 'react-i18next';

import {useExhibitionContext} from '../components/ExhibitionContext';
import getAnalyticsExhibitionData from '../utils/getAnalyticsExhibitionData';
import {AnalyticsPageEventParams} from '../types';
import useAnalytics from './useAnalytics';

function useAnalyticsReporter() {
  const exhibition = useExhibitionContext();
  const location = useLocation();
  const analyticsInst = useAnalytics();
  const {i18n} = useTranslation();

  useEffect(() => {
    const inst = analyticsInst();
    if (typeof window === 'undefined' || !inst) {
      return;
    }

    if (window.hasOwnProperty('gtag')) {
      (
        window as typeof window & {
          gtag: (method: string, id: string, values: object) => void;
        }
      ).gtag(
        'config',
        (inst.app.options as {measurementId: string}).measurementId,
        {send_page_view: false},
      );
    }
    const pagePath = `${location.pathname}${location.search}`;
    inst.setCurrentScreen(pagePath);
    const data: AnalyticsPageEventParams = {
      ...getAnalyticsExhibitionData(exhibition, i18n),
      page_location: window.location.href,
      page_path: pagePath,
    };
    inst.logEvent(firebase.analytics.EventName.PAGE_VIEW, data);
  }, [location, exhibition, analyticsInst]);
}

export default useAnalyticsReporter;
