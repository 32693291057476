import {PersonnelStatus} from './types';

export const PAGE_WIDTH = 1100;
export const PAGE_MARGIN = 15;
export const FOOT_OVERLAP = 30;

export const BLACK = '#1a171b';
export const WHITE = '#fefefe';
export const ALMOST_WHITE = '#fbfbfa';
export const ACCENT_RED = '#e1001a';
export const DARK_GRAY = '#58585a';
export const LIGHT_GRAY = '#d9dadb';
export const LIGHTER_GRAY = '#eceded';
export const GRAY = '#b1b3b4';
export const MILD_GRAY = '#87888a';
export const WINDOW_GRAY = '#ccc';
export const GREEN = '#4aaa4c';

export const INSTITUTION_COLOR = '#002d62';

export const FONT_THIN = '300';
export const FONT_REGULAR = '400';
export const FONT_MEDIUM = '500';
export const FONT_BOLD = '700';

export const DATETIME_FORMAT = "yyyy-MM-dd'T'HH:mm";
export const LONG_DATE_FORMAT_SAME_YEAR = 'EEEE dd MMMM';
export const LONG_DATE_FORMAT = 'EEEE dd MMMM yyyy';
export const DATE_FORMAT = 'dd/MM/yyyy';
export const TIME_FORMAT = 'HH:mm';

export const ACCEPTS_COOKIES_NAME = 'accepts-cookies';
export const ALLOWED_TAGS =
  '<b><strong><br><em><hr><i><p><s><span><table><tr><td><tbody><thead><tfoot><u><ul><li><ol><cite><blockquote><h2><h3><h4><h5><h6><code><tt><a><ins><del>';

export const DEVICE_SIZE = {
  mobile: 768,
  tablet: 1024,
};

export const DEVICE = {
  mobile: `(max-width: ${DEVICE_SIZE.mobile - 1}px)`,
  tablet: `(min-width: ${DEVICE_SIZE.mobile}px)`,
  desktop: `(min-width: ${DEVICE_SIZE.tablet}px)`,
};

export type DeviceClass = keyof typeof DEVICE;

export const SOCIALS = {
  fb: 'Facebook',
  in: 'LinkedIn',
  am: 'Instagram',
  tw: 'Twitter',
};

export const MIMETYPES = {
  docs: ['application/pdf'],
  office: [
    'application/msword',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/msexcel',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'application/mspowerpoint',
    'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  ],
  images: ['image/png', 'image/jpeg', 'image/gif'],
};

export const personnelStatusColor: {[status in PersonnelStatus]: string} = {
  [PersonnelStatus.available]: '#97bf0d',
  [PersonnelStatus.occupied]: '#e2001a',
  [PersonnelStatus.away]: MILD_GRAY,
};
