import React from 'react';

import SmartPageChrome from './SmartPageChrome';
import Loading from './Loading';

const LoadingPage = () => (
  <SmartPageChrome>
    <Loading />
  </SmartPageChrome>
);

export default LoadingPage;
