// (function () {
//   function onTidioChatApiReady() {
//     // Code after chat loaded
//   }
//   if (window.tidioChatApi) {
//     window.tidioChatApi.on('ready', onTidioChatApiReady);
//   } else {
//     document.addEventListener('tidioChat-ready', onTidioChatApiReady);
//   }
// })();

import {noop} from 'lodash';
import {DependencyList, useEffect, useState} from 'react';

interface TidioVisitor {
  distinct_id?: string; // Unique user ID in your system
  email?: string; // User email
  name?: string; // Visitor name
  city?: string; // City
  postcode?: string;
  phone?: string;
  country?: string; // Country
}

interface TidioProperties {
  business?: string;
  exhibition?: string;
}

interface TidioApi {
  setVisitorData: (visitor: TidioVisitor) => void;
  setContactProperties: (properties: TidioProperties) => void;
  on: (event: string, callback: () => void) => void;
}

type WindowTidio = typeof window & {tidioChatApi?: TidioApi};

export const useTidio = (
  callback: (tidioApi: TidioApi) => void,
  deps: DependencyList,
) => {
  const [tidioReady, setReady] = useState<boolean>(false);

  useEffect(() => {
    if (!tidioReady) {
      if (typeof window !== 'undefined') {
        const makeReady = () => setReady(true);
        if ((window as WindowTidio).tidioChatApi) {
          (window as WindowTidio).tidioChatApi!.on('ready', makeReady);
        } else {
          document.addEventListener('tidioChat-ready', makeReady);
          return () => {
            document.removeEventListener('tidioChat-ready', makeReady);
          };
        }
      }
    } else {
      callback((window as WindowTidio).tidioChatApi!);
    }
    return noop;
  }, [tidioReady, ...deps]);
};
