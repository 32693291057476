import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import firebase from 'firebase/app';
import nl2br from 'react-nl2br';
import Linkify from 'react-linkify';
import styled from 'styled-components';
import {useTranslation} from 'react-i18next';

import {ACCENT_RED, DARK_GRAY, FONT_THIN, WHITE} from '../const';

import {ChatMessage, Exhibition, RecordRef, User} from '../types';
import userName from '../utils/userName';
import reportSnapshotError from '../utils/reportSnapshotError';
import makeRecord from '../utils/makeRecord';
import isEmpty from '../utils/isEmpty';
import Loading from './Loading';
import UserName from './UserName';
import PlainButton from './PlainButton';
import formatDate from '../utils/formatDate';
import ChatScroller from './ChatScroller';
import {TransparentButton} from './global';
import SendIcon from '../assets/svg/send';

// tslint:disable-next-line: no-var-requires
const sound = require('../assets/sounds/key.mp3');

const ChatContainer = styled.div`
  background: ${WHITE};
`;

const MessageEntry = styled.div<{isOwner: boolean}>`
  display: block;
  padding: 0;
  margin: 0 0 1em;
  ${({isOwner}) => (isOwner ? 'text-align: right;' : '')}
`;

const MessageUser = styled.span`
  font-weight: ${FONT_THIN};
  font-style: italic;
  display: block;
`;

const ChatSendBox = styled.div`
  display: flex;
  align-items: flex-start;
`;

const ChatInput = styled.textarea`
  font-size: 1em;
  font-family: inherit;
  -webkit-appearance: none;
  border: none;
  background: #fff;
  padding: 10px;
  height: 4em;
  display: block;
  box-sizing: border-box;
  width: 100%;
  font-size: 14px;
  flex: 1 1 auto;
`;

const SendButton = styled(TransparentButton)`
  cursor: pointer;
  flex: 0 0 28px;
  height: 28px;
  margin-top: 6px;
`;

const DeleteButton = styled(PlainButton)`
  padding: 0;
  font-size: 15px;
  line-height: 15px;
  width: 15px;
  height: 15px;
  text-align: center;
  display: inline-block;
  margin-left: 10px;
  vertical-align: middle;
  box-sizing: border-box;
  &:active {
    color: ${DARK_GRAY};
    background: ${WHITE};
    border: 1px solid ${DARK_GRAY};
  }
`;

const NameTag = styled.span<{color?: string}>`
  ${({color}) => (color ? `color: ${color};` : '')}
`;

const MyLinkify = React.memo(({children}: {children: string}) => (
  <Linkify
    componentDecorator={(href, text, key) => (
      <a href={href} target="_blank" key={key}>
        {decodeURIComponent(
          text.replace(
            /https:\/\/firebasestorage\..+%2[fF]([^?]+)(?:\?.+)?$/,
            '$1',
          ),
        )}
      </a>
    )}>
    {nl2br(children)}
  </Linkify>
));

interface PropsType {
  className?: string;
  exhibition: Exhibition;
  position: firebase.firestore.CollectionReference<ChatMessage>;
  reportLast?: boolean;
  user?: User;
  extraMessages?: string[];
  isOwner?: boolean;
  msgSound?: boolean;
  onNewMessage?: () => void;
}

const FirestoreChat = ({
  className,
  position,
  reportLast,
  user,
  extraMessages,
  isOwner,
  msgSound,
  onNewMessage,
}: PropsType) => {
  const [chatMessage, setChatMessage] = useState<string>('');
  const [messages, setMessages] = useState<RecordRef<ChatMessage>[] | null>(
    null,
  );
  const sentExtras = useRef<number>(0);
  const userUsername = useMemo(() => (user ? userName(user) : ''), [user]);
  const audio = useRef<HTMLAudioElement>(null);
  const lastMsg = useRef<string>();
  const {i18n, t} = useTranslation();

  useEffect(() => {
    return position.orderBy('at').onSnapshot((snapshot) => {
      setMessages(snapshot.docs.map(makeRecord));
    }, reportSnapshotError(position));
  }, [position]);

  const handleChangeMessage = useCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      e.preventDefault();
      setChatMessage(e.target.value);
    },
    [],
  );

  const handleSendMessage = useCallback(
    async (msg: string) => {
      if (!user || isEmpty(msg)) {
        return;
      }
      const batch = position.firestore.batch();
      const entry: ChatMessage = {
        sender: user.ref,
        at: firebase.firestore.FieldValue.serverTimestamp(),
        message: msg,
      };
      if (isOwner) {
        entry.owner = isOwner;
      }
      batch.set(position.doc(), entry);
      if (reportLast) {
        batch.update(position.parent!, {
          lastMsg: firebase.firestore.FieldValue.serverTimestamp(),
        });
      }
      await batch.commit();
    },
    [user, isOwner, reportLast],
  );

  useEffect(() => {
    if (!extraMessages || sentExtras.current >= extraMessages.length) {
      return;
    }
    const toSend =
      sentExtras.current === 0
        ? extraMessages
        : extraMessages.slice(sentExtras.current);
    sentExtras.current = extraMessages.length;
    (async () => {
      await Promise.all(toSend.map(handleSendMessage));
    })();
  }, [extraMessages]);

  useEffect(() => {
    if (((!msgSound || !audio.current) && !onNewMessage) || !messages) {
      lastMsg.current = '';
      return;
    }
    if (
      messages.length === 0 ||
      messages[messages.length - 1]!.sender.id === user?.ref.id ||
      lastMsg.current === messages[messages.length - 1].ref.id ||
      !lastMsg.current
    ) {
      lastMsg.current =
        messages && messages.length > 0
          ? messages[messages.length - 1].ref.id
          : '';
      return;
    }

    if (msgSound && audio.current) {
      try {
        audio.current.currentTime = 0;
        audio.current.play();
      } catch (e) {
        console.error(e);
      }
    }
    if (onNewMessage) {
      try {
        onNewMessage();
      } catch (e) {
        console.error(e);
      }
    }

    lastMsg.current = messages[messages.length - 1].ref.id;
  }, [msgSound, audio, messages]);

  const handleDelete = useCallback(
    (ref: firebase.firestore.DocumentReference<ChatMessage>) => {
      ref.delete();
    },
    [],
  );

  const handleSendButton = () => {
    handleSendMessage(chatMessage.trim());
    setChatMessage('');
  };

  const timeformat = t('date_format.time');

  return (
    <ChatContainer className={className}>
      {msgSound && <audio ref={audio} src={sound} preload="auto" />}
      <ChatScroller
        lastMsgId={
          messages && messages.length > 0
            ? messages[messages.length - 1].ref.id
            : undefined
        }>
        {messages === null ? (
          <Loading />
        ) : (
          messages.map((m) => {
            const isUser = m.sender.id === user?.ref.id;
            const nameColor = m.owner ? ACCENT_RED : undefined;
            return (
              <MessageEntry key={m.ref.id} isOwner={isUser}>
                <MessageUser>
                  {formatDate(
                    (m.at as firebase.firestore.Timestamp)?.toDate() ||
                      new Date(),
                    timeformat,
                    {i18n},
                  )}{' '}
                  –{' '}
                  {isUser ? (
                    <NameTag color={nameColor}>{userUsername}</NameTag>
                  ) : (
                    <>
                      <NameTag
                        color={nameColor}
                        as={UserName}
                        user={m.sender}
                        business={true}
                      />
                      {isOwner && (
                        <DeleteButton
                          type="button"
                          onClick={() => handleDelete(m.ref)}>
                          &times;
                        </DeleteButton>
                      )}
                    </>
                  )}
                </MessageUser>
                <MyLinkify>{m.message}</MyLinkify>
              </MessageEntry>
            );
          })
        )}
      </ChatScroller>
      <ChatSendBox>
        <ChatInput
          placeholder={t('chat.message_placeholder')}
          value={chatMessage}
          onChange={handleChangeMessage}
          onKeyPress={(e) => {
            if (e.key === 'Enter' && !e.shiftKey) {
              e.preventDefault();
              handleSendButton();
            }
          }}
        />
        <SendButton type="button" onClick={handleSendButton}>
          <SendIcon />
        </SendButton>
      </ChatSendBox>
    </ChatContainer>
  );
};

export default FirestoreChat;
