import * as React from 'react';

function Triangle(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={10} height={5} viewBox="0 0 10 5" {...props}>
      <path fill="currentColor" d="M4.672 0L0 4.672h9.344z" />
    </svg>
  );
}

export default Triangle;
