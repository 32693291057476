import * as React from 'react';
import {BLACK} from '../../const';

function CloseIcon({
  fill = BLACK,
  width,
  height,
  ...props
}: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={width ?? 23}
      height={typeof width === 'number' ? width : height ?? 23}
      viewBox="0 0 23 23"
      {...props}>
      <path
        fill={fill}
        d="M22.842 4.553L18.813.483 11.79 7.569 4.698.484.737 4.554l7.018 7.016-7.018 7.022 3.961 4.064 7.091-7.085 7.024 7.085 4.03-4.064-7.02-7.022z"
        fillRule="evenodd"
      />
    </svg>
  );
}

export default CloseIcon;
