import React from 'react';
import styled, {createGlobalStyle} from 'styled-components';
import {useTranslation} from 'react-i18next';

import {useExhibitionContext} from '../ExhibitionContext';
import ExhibitionHeader from './ExhibitionHeader';
import {Sizer} from '../global';
import SiteChrome from '../SiteChrome';
import {FOOT_OVERLAP, DEVICE, BLACK} from '../../const';
import {useLogin} from '../../contexts/UserContext';
import VerifyEmailMessage from '../VerifyEmailMessage';
import cutSlash from '../../utils/cutSlash';
import AcceptBrowserNotifications from '../AcceptBrowserNotifications';

interface PropsType {
  children?: React.ReactNode;
  isHome?: boolean;
  centeredLogo?: boolean;
  userWidget?: boolean;
  backgroundColor?: string;
  onLogoClick?: (e: React.MouseEvent) => void;
}

const ExhibitionStyle = createGlobalStyle<{
  externalBackgroundColor?: string;
  externalBackgroundImage?: string;
  textColor?: string;
}>`
  body {
    background: ${({externalBackgroundColor, externalBackgroundImage}) =>
      `${externalBackgroundColor || '#fff'}${
        externalBackgroundImage != null
          ? ` url('${externalBackgroundImage}') repeat 50% 0`
          : ''
      }`};
    color: ${({textColor}) => textColor || BLACK};
  }
`;

const Container = styled(Sizer)`
  padding-top: 10px;
  flex: 1 0 auto;
  padding-bottom: ${FOOT_OVERLAP}px;
`;

const ExhibitionChrome = ({
  children,
  isHome = false,
  centeredLogo,
  userWidget = true,
  backgroundColor,
  onLogoClick,
}: PropsType) => {
  const data = useExhibitionContext()!;
  const login = useLogin();
  const {i18n, t} = useTranslation();
  const {externalBackgroundColor, externalBackgroundImage, textColor} = data;
  return (
    <SiteChrome
      topMenuEntries={[
        {
          lang: {
            [i18n.languages[0]]: {
              name: t('events.calendar_short'),
            },
          },
          url: `${cutSlash(data.baseUrl)}/events/`,
        },
      ]}>
      <ExhibitionStyle
        externalBackgroundColor={externalBackgroundColor}
        externalBackgroundImage={externalBackgroundImage}
        textColor={textColor}
      />
      <ExhibitionHeader
        isHome={isHome}
        centeredLogo={centeredLogo}
        userWidget={userWidget}
        onLogoClick={onLogoClick}
      />
      <Container background={backgroundColor || data.backgroundColor}>
        {children}
        {login.user && !login.valid && <VerifyEmailMessage />}
        {login.requestingPerm && <AcceptBrowserNotifications />}
      </Container>
    </SiteChrome>
  );
};

export default ExhibitionChrome;
