import {Translated, Falsy, TranslateMap} from '../types';
import {useTranslation} from 'react-i18next';
import getTranslated from './getTranslated';

export default function useTranslated<T extends TranslateMap>(
  data: Translated<T> | Falsy,
): T | undefined {
  const {i18n} = useTranslation();
  return getTranslated(data, i18n);
}
