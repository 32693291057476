import React from 'react';
import {useTranslation} from 'react-i18next';
import supportedLangs from '../i18n/supportedLangs';

import {Input, SelectWrap} from './FormCommon';

interface PropsType {
  className?: string;
  id: string;
  value: string;
  onLanguageChange: (lang: string) => void;
  onTranslate?: (source: string, dest: string) => void;
}

const LanguageSelector = ({
  className,
  id,
  value,
  onLanguageChange,
  onTranslate,
}: PropsType) => {
  const handleLanguageChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    e.preventDefault();
    const [source, dest] = e.target.value.split('->', 2);
    if (source && dest) {
      onTranslate!(source, dest);
    } else {
      onLanguageChange(e.target.value);
    }
  };
  const {t} = useTranslation();

  return (
    <SelectWrap className={className}>
      <Input as="select" id={id} value={value} onChange={handleLanguageChange}>
        {supportedLangs.map((lang) => (
          <option key={lang.code} value={lang.code}>
            {lang.name}
          </option>
        ))}
        {onTranslate && (
          <optgroup label={t('translate.label')}>
            {value !== 'it' && (
              <option value={`it->${value}`}>
                {t('translate.from', {lang: 'it'})}
              </option>
            )}
            {value !== 'en' && (
              <option value={`en->${value}`}>
                {t('translate.from', {lang: 'en'})}
              </option>
            )}
          </optgroup>
        )}
      </Input>
    </SelectWrap>
  );
};

export default LanguageSelector;
