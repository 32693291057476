import React, {useCallback} from 'react';
import styled from 'styled-components';
import {useTranslation} from 'react-i18next';
import firebase from 'firebase/app';

import {useLogin} from '../contexts/UserContext';
import {Personnel, PersonnelStatus} from '../types';
import {ACCENT_RED} from '../const';
import Switch from './Switch';
import {Message} from './global';
import usePersonnelAvailabilityControl from '../data/usePersonnelAvailabilityControl';

interface PropsType {
  personnel: Personnel;
  children?: React.ReactNode;
  className?: string;
  disabled?: boolean;
}

const AvailableLabel = styled.label`
  margin-right: 10px;
`;

const PersonnelAvailabilityControl = ({
  personnel,
  children,
  className,
  disabled,
}: PropsType) => {
  const login = useLogin();
  const {t} = useTranslation();
  const setAvailability = usePersonnelAvailabilityControl(
    personnel,
    login?.user,
  );

  const setAway = useCallback((away: boolean) => {
    setAvailability(away ? PersonnelStatus.away : PersonnelStatus.available);
  }, []);

  return (
    <Message className={className}>
      <AvailableLabel htmlFor="available">
        {children || t('personnel_status.available')}
      </AvailableLabel>
      <Switch
        checked={personnel!.status !== PersonnelStatus.away}
        onChange={(value) => setAway(!value)}
        id="available"
        unCheckedColor={ACCENT_RED}
        checkedChildren="Sì"
        unCheckedChildren="No"
        disabled={disabled}
      />
    </Message>
  );
};

export default PersonnelAvailabilityControl;
