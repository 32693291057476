import {useCallback} from 'react';
import firebase from 'firebase/app';
import {Personnel, PersonnelStatus, User} from '../types';

const usePersonnelAvailabilityControl = (
  personnel?: Personnel | null | false,
  user?: User | null | false,
) => {
  return useCallback(
    async (status: PersonnelStatus) => {
      if (!personnel || !user || user.ref.id !== personnel.user.id) {
        return;
      }
      const db = personnel.ref.firestore;
      await db.runTransaction(async (transaction) => {
        const currentPersonnel = await transaction.get(personnel.ref);
        if (!currentPersonnel.exists) {
          return;
        }
        const currentStatus = currentPersonnel.data()!.status;
        transaction.update(personnel.ref, 'status', status);
        if (
          (currentStatus === PersonnelStatus.away) !==
          (status === PersonnelStatus.away)
        ) {
          transaction.update(
            personnel.ref.parent!.parent!,
            'availPersonnel',
            firebase.firestore.FieldValue.increment(
              status === PersonnelStatus.away ? -1 : 1,
            ),
          );
        }
      });
    },
    [personnel, user],
  );
};

export default usePersonnelAvailabilityControl;
