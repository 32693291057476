import React, {useEffect, useRef} from 'react';
import styled from 'styled-components';
import {DEVICE, PAGE_MARGIN, WINDOW_GRAY} from '../const';
import supportsPassive from '../utils/supportsPassive';
import CloseButton from './CloseButton';

interface PropsType {
  children?: React.ReactNode;
  className?: string;
  closeable?: boolean;
  onRequestClose?: () => void;
}

const Background = styled.div`
  background: rgba(0, 0, 0, 0.7);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 101;
`;

const ModalContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  background: #fff;
  border-radius: 4px;
  outline: none;
  z-index: 102;
  width: 100%;
  max-width: 650px;
  max-height: 100%;
  box-sizing: border-box;
  display: flex;
  flex-flow: column nowrap;

  @media ${DEVICE.tablet} {
    left: 50%;
    transform: translate(-50%, -50%);
    border: 1px solid ${WINDOW_GRAY};
    max-height: 80%;
  }
`;

const Scroller = styled.div`
  flex: 1 1 100%;
  overflow: auto;
  padding: 20px ${PAGE_MARGIN}px;

  @media ${DEVICE.tablet} {
    padding: 40px;
  }
`;

type ContentPropsType = React.ComponentProps<typeof ModalContainer> & {
  outerChildren?: React.ReactNode;
  scrollerStyle?: React.CSSProperties;
};

export const ModalContent = React.forwardRef(
  (
    {children, outerChildren, scrollerStyle, ...props}: ContentPropsType,
    ref,
  ) => (
    <ModalContainer {...props} ref={ref}>
      {outerChildren}
      <Scroller style={scrollerStyle}>{children}</Scroller>
    </ModalContainer>
  ),
);

const FloatingMessage = ({
  children,
  className,
  closeable,
  onRequestClose,
}: PropsType) => {
  const contentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (typeof window === 'undefined') {
      return;
    }

    const onScroll = () => {
      const content = contentRef.current;
      if (content != null) {
        const background = content.parentElement!;
        const bounds = content.getBoundingClientRect();
        const backBounds = background.getBoundingClientRect();
        const top = Math.min(
          backBounds.height - bounds.height / 2 - 20,
          Math.max(
            bounds.height / 2 + 20,
            window.innerHeight / 2 - backBounds.top,
          ),
        );
        content.style.top = `${top}px`;
      }
    };

    onScroll();

    const param = supportsPassive() ? {passive: true} : false;
    window.addEventListener('scroll', onScroll, param);
    window.addEventListener('resize', onScroll, param);

    return () => {
      window.removeEventListener('scroll', onScroll);
      window.removeEventListener('resize', onScroll);
    };
  }, [contentRef]);

  return (
    <Background>
      <ModalContent
        className={className}
        ref={contentRef}
        outerChildren={closeable && <CloseButton onClick={onRequestClose} />}>
        {children}
      </ModalContent>
    </Background>
  );
};

export default FloatingMessage;
