import {differenceInSeconds} from 'date-fns';

// difference, seconds, minutes, hours, days, months
export type DateDiff = [number, number, number, number, number, number];

function dateDiff(a: Date | number, b: Date | number): DateDiff {
  const diff = differenceInSeconds(a, b);
  let d = diff;
  const secs = diff % 60;
  d = (d - secs) / 60;
  const mins = d % 60;
  d = (d - mins) / 60;
  const hours = d % 24;
  d = (d - hours) / 24;
  const days = d % 31;
  d = Math.floor((d - days) / 31);
  return [diff, secs, mins, hours, days, d];
}

export default dateDiff;
