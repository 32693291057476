import type firebase from 'firebase/app';

import {AddressData, UniversalDocRef} from './common';
import {ExhibitorData, ExhibitorEditableData} from './exhibitor';
import {UserData} from './user';

export interface ChangelogData<
  D extends object,
  E extends UniversalDocRef<ExhibitorData> = firebase.firestore.DocumentReference<ExhibitorData>,
  P extends UniversalDocRef<UserData<E>> = firebase.firestore.DocumentReference<
    UserData<E>
  >,
> {
  modifiedBy: P;
  date: firebase.firestore.Timestamp;
  new?: boolean;
  oldValue?: D;
  newValue: D;
  notified?: {
    mynet?: string;
  };
}

export const AddressDataKeys: (keyof AddressData)[] = [
  'address',
  'city',
  'zipcode',
  'country',
  'state',
  'phone',
  'email',
];

export const ExhibitorEditableDataKeys: (keyof ExhibitorEditableData)[] = [
  ...AddressDataKeys,
  'lang',
  'name',
  'geo',
  'website',
  'social',
  'logo',
  'themes',
  'videos',
  'gallery',
  'documentUrls',
  'brands',
  'slug',
];

export interface ExhibitorChangelogData<
  E extends UniversalDocRef<ExhibitorData> = firebase.firestore.DocumentReference<ExhibitorData>,
  P extends UniversalDocRef<UserData<E>> = firebase.firestore.DocumentReference<
    UserData<E>
  >,
> extends ChangelogData<ExhibitorEditableData, E, P> {}
