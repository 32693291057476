import type firebase from 'firebase/app';
import {UserData, UserPublicData, userPublicKeys, UserRole} from './user';
import {RecordRef, UniversalDocRef} from './common';
import {ExhibitorData} from './exhibitor';

export enum PersonnelStatus {
  available = 'available',
  occupied = 'occupied',
  away = 'away',
}

export const personnelPublicKeys: (keyof UserPublicData | 'email')[] = [
  ...userPublicKeys,
  'email',
];

/**
 * Collection `/exhibitions/<exhibitionId>/exhibitors/<exhibitorId>/personnel/<personnelId>`
 */
export interface PersonnelData<
  E extends UniversalDocRef<ExhibitorData> = firebase.firestore.DocumentReference<ExhibitorData>,
  P extends UniversalDocRef<UserData<E>> = firebase.firestore.DocumentReference<
    UserData<E>
  >,
> extends UserPublicData {
  email: string;
  user: P;
  status: PersonnelStatus;
  role: UserRole;
  lastCheck?: firebase.firestore.Timestamp;
}

export type Personnel = RecordRef<PersonnelData>;
