import italianFlag from '../assets/svg/flags/it';
import englishFlag from '../assets/svg/flags/en';

const supportedLangs: {
  code: string;
  name: string;
  flag: React.ElementType;
}[] = [
  {
    code: 'it',
    name: 'Italiano',
    flag: italianFlag,
  },
  {
    code: 'en',
    name: 'English',
    flag: englishFlag,
  },
];

export default supportedLangs;
