import React, {useState} from 'react';
import styled from 'styled-components';
import firebase from 'firebase/app';
import {useTranslation} from 'react-i18next';
import {addMinutes} from 'date-fns';

import PlainButton from './PlainButton';
import {useLogin} from '../contexts/UserContext';
import {ACCENT_RED} from '../const';
import {ButtonContainer} from './FormCommon';
import SimpleFloatingMessage from './SimpleFloatingMessage';
import useDateDiff from '../data/useDateDiff';
import Loading from './Loading';

const BottomButtonContainer = styled(ButtonContainer)`
  margin-top: 20px;
`;

const RefreshButton = styled(PlainButton)`
  background: ${ACCENT_RED};
`;

const Countdown = styled.span`
  padding-top: 20px;
  font-size: 11px;
  display: block;
`;

const VerifyEmailMessage = () => {
  const login = useLogin();
  const {t} = useTranslation();
  const [expireVerify, setExpireVerify] = useState<Date | undefined>();
  const [loading, setLoading] = useState<boolean>(false);
  const [, secs, mins] = useDateDiff(expireVerify, 'now', () =>
    setExpireVerify(undefined),
  );

  return (
    <SimpleFloatingMessage
      buttons={
        !loading && (
          <BottomButtonContainer>
            <PlainButton
              type="button"
              disabled={expireVerify != null}
              onClick={async () => {
                setLoading(true);
                let wait = 2;
                try {
                  await firebase.auth().currentUser?.sendEmailVerification();
                } catch (e: any) {
                  if (e.code === 'auth/too-many-requests') {
                    wait = 10;
                  }
                } finally {
                  setLoading(false);
                  setExpireVerify(addMinutes(new Date(), wait));
                }
              }}>
              {t('verify_email.resend')}
            </PlainButton>
            <RefreshButton
              onClick={() => {
                if (typeof window !== 'undefined') {
                  const {location} = window;
                  const clean = location.href
                    .replace(/([&?])registered=1&?/, '$1')
                    .replace(/\?$/, '');
                  location.href = `${clean}${
                    clean.indexOf('?') === -1 ? '?' : '&'
                  }registered=1`;
                }
              }}>
              {t('verify_email.done')}
            </RefreshButton>
          </BottomButtonContainer>
        )
      }>
      {loading ? (
        <Loading />
      ) : (
        t(
          login.user != null
            ? 'verify_email.message_address'
            : 'verify_email.message',
          {
            address: login.user != null ? login.user.email : '',
          },
        )
      )}
      {expireVerify != null && (
        <Countdown>{t('verify_email.resend_in', {mins, secs})}</Countdown>
      )}
    </SimpleFloatingMessage>
  );
};
export default VerifyEmailMessage;
