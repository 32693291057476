import {useEffect, useState} from 'react';
import dateDiff, {DateDiff} from '../utils/dateDiff';

type DateRef = Date | number | (() => Date | number) | 'now';

function getDateFromRef(ref: DateRef): Date | number {
  if (ref === 'now') {
    return new Date();
  }
  if (typeof ref === 'object' || typeof ref === 'number') {
    return ref;
  }
  return ref();
}

function useDateDiff(
  dateA?: DateRef,
  dateB?: DateRef,
  onDone?: () => void,
): DateDiff {
  const [diff, setDiff] = useState<DateDiff>(() =>
    dateA && dateB
      ? dateDiff(getDateFromRef(dateA), getDateFromRef(dateB))
      : [0, 0, 0, 0, 0, 0],
  );
  const dateRefA =
    typeof dateA === 'object' && dateA instanceof Date
      ? dateA.getTime()
      : dateA;
  const dateRefB =
    typeof dateB === 'object' && dateB instanceof Date
      ? dateB.getTime()
      : dateB;

  useEffect(() => {
    if (
      !dateRefA ||
      !dateRefB ||
      (typeof dateRefA === 'object' && typeof dateRefB === 'object')
    ) {
      return;
    }
    let timeout: NodeJS.Timeout | undefined;
    function update() {
      const d = dateDiff(getDateFromRef(dateRefA!), getDateFromRef(dateRefB!));
      setDiff(d);
      if (d[0] <= 0 && onDone && timeout != null) {
        const t = timeout;
        timeout = undefined;
        clearTimeout(t);
        onDone();
      }
    }
    timeout = setInterval(update, 1000);
    update();
    return () => {
      if (timeout != null) {
        clearTimeout(timeout);
      }
    };
  }, [dateRefA, dateRefB]);

  return diff;
}

export default useDateDiff;
