import React, {useEffect, useLayoutEffect} from 'react';
import {Route, Switch, useHistory} from 'react-router-dom';
import ReactModal from 'react-modal';
import loadable from '@loadable/component';

import './App.css';

import Exhibition from './pages/exhibition';
import useHostname from './data/useHostname';
import useAnalyticsReporter from './data/useAnalyticsReporter';
import LoadingPage from './components/LoadingPage';
import {DeviceClassProvider} from './contexts/DeviceClassContext';
import getTranslatedValue from './utils/getTranslatedValue';

const LoadableEntryPoint = loadable(
  () => import(/* webpackChunkName: "entrypoint" */ './pages/EntryPoint'),
  {cacheKey: () => 'entrypoint'},
);

ReactModal.setAppElement('#root');

const App = () => {
  const hostname = useHostname();
  const history = useHistory();
  useAnalyticsReporter();

  useEffect(() => {
    const {hash} = history.location;
    if (
      typeof document !== 'undefined' &&
      hash &&
      document.getElementById(hash.substr(1))
    ) {
      // Check if there is a hash and if an element with that id exists
      document
        .getElementById(hash.substr(1))
        ?.scrollIntoView({behavior: 'smooth'});
    }
  }, [history.location.hash]); // Fires every time hash changes

  const exhibition = <Exhibition />;

  return (
    <DeviceClassProvider>
      {hostname === process.env.RAZZLE_BASE_DOMAIN ? (
        <Switch>
          <Route exact={true} path="/">
            <LoadableEntryPoint fallback={<LoadingPage />} />
          </Route>
          <Route path="/:exhibition">{exhibition}</Route>
        </Switch>
      ) : (
        exhibition
      )}
    </DeviceClassProvider>
  );
};

export default App;
