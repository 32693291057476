import React from 'react';
import styled from 'styled-components';
import createDecorator from 'final-form-focus';

import PlainButton from './PlainButton';
import {
  LIGHT_GRAY,
  DARK_GRAY,
  BLACK,
  FONT_REGULAR,
  FONT_BOLD,
  MILD_GRAY,
} from '../const';
import {Decorator, FieldValidator} from 'final-form';
import isEmpty from '../utils/isEmpty';
import {TFunction} from 'i18next';

export const Submit = styled(PlainButton)`
  background: ${({theme: {accentColor}}) => accentColor};
  margin: 0;
`;

export const ButtonContainer = styled.div`
  text-align: center;
  margin: 10px 0;
  display: flex;
  width: 100%;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  button + button,
  a + button,
  button + a {
    margin-left: 10px;
  }
`;

export const Input = styled.input`
  -webkit-appearance: none;
  box-sizing: border-box;
  width: 100%;
  border: 1px solid ${LIGHT_GRAY};
  border-radius: 5px;
  padding: 10px;
  font-size: 1em;
  font-family: inherit;
`;

export const WysiwygWrap = styled(Input)``;

export const SelectWrap = styled.div`
  position: relative;
  &:after {
    position: absolute;
    top: 50%;
    margin-top: -4px;
    right: 10px;
    content: '';
    border-top: 8px solid ${BLACK};
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    pointer-events: none;
  }
  &.is-disabled:after {
    border-top-color: ${LIGHT_GRAY};
  }
  select {
    padding-right: 30px;
  }
`;

export const FormTag = styled.form`
  margin: 0 auto;
  width: 100%;
  box-sizing: border-box;
  padding: 0;
  display: block;
  font-size: 14px;

  input.invalid,
  select.invalid,
  textarea.invalid,
  ${WysiwygWrap}.invalid {
    border-color: #f00;
    background: rgba(255, 0, 0, 0.2);
  }

  textarea {
    min-height: 8em;
  }

  ${WysiwygWrap} {
    padding: 0;
    .rdw-editor-toolbar {
      border: none;
      border-bottom: 1px solid ${LIGHT_GRAY};
    }
    .rdw-editor-main {
      padding: 10px;
    }
  }
`;

export const FormRow = styled(({required, ...props}) => <div {...props} />)<{
  width?: number;
}>`
  margin: 20px 0;
  flex: ${({width = 5}) => `${width} ${width} ${width}0%`};
`;

export const Label = styled(({required, ...props}) => <label {...props} />)<{
  required?: boolean;
}>`
  display: block;
  margin: 0 0 10px;
  font-weight: ${FONT_BOLD};
  text-align: left;
  a {
    color: inherit;
    text-decoration: underline;
  }
  ${({required}) =>
    required
      ? `&:after {
    content: ' *';
    display: inline;
  }`
      : ''}
`;

export const LabelHelpText = styled.div`
  font-weight: normal;
`;

export const HelperText = styled.p`
  font-size: 12px;
  color: ${MILD_GRAY};
  margin: 12px 0;
`;

export const Group = styled.fieldset`
  -webkit-appearance: none;
  border: none;
  padding: 0;
  margin: 30px 0 20px;
`;

export const Split = styled(({wrap, ...props}) => <div {...props} />)<{
  wrap?: 'wrap' | 'nowrap';
}>`
  display: flex;
  margin: 20px -10px;
  flex-wrap: ${({wrap}) => wrap || 'nowrap'};
  ${FormRow} {
    box-sizing: border-box;
    padding: 0 10px;
    margin: 0;
  }
`;

export const Legend = styled.legend`
  font-size: 11px;
  color: ${DARK_GRAY};
  text-transform: uppercase;
  letter-spacing: 0.1em;
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0;
  width: 100%;
  &:after {
    content: '';
    flex: 1 1 1px;
    height: 1px;
    background: ${LIGHT_GRAY};
    margin-left: 5px;
    display: block;
  }
`;

export const Distribute = styled.div`
  display: flex;
  align-items: baseline;
`;

export const DistributedSelect = styled(({width, ...props}) => (
  <SelectWrap {...props} />
))<{
  width?: number;
}>`
  flex: ${({width}) => `${width} ${width} ${width}0%`};
  & + & {
    margin-left: 5px;
  }
`;

export const Divider = styled.div`
  flex: 0 0 auto;
  text-align: center;
  font-weight: bold;
  margin: 0 5px;
`;

export const CheckFormRow = styled(FormRow)`
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  input {
    flex: 0 0 auto;
    margin: 0;
    padding: 0;
  }
  ${Label} {
    padding-left: 10px;
    flex: 1 1 70%;
    box-sizing: border-box;
    margin-bottom: 0;
  }
`;

export const FormError = styled.div`
  color: red;
  margin: 5px 0;
  text-align: center;
  font-weight: ${FONT_REGULAR};
  font-size: 14px;
  display: block;
  flex: 1 0 100%;

  a {
    color: inherit;
    text-decoration: underline;
  }
`;

export interface BaseFieldRowPropsType {
  name: string;
  label?: string;
  customLabel?: React.ReactNode;
  id: string;
  readonly?: boolean;
  onChange?: (event: React.ChangeEvent<string> | any) => void;
}

export interface FieldRowPropsType extends BaseFieldRowPropsType {
  type?: string;
  component: React.ElementType<any> | 'input' | 'select' | 'textarea';
  width?: number;
  children?: React.ReactNode;
  wrapComponent?: string | React.ElementType;
  inputProps?: object;
  wrapProps?: object;
  parse?: (value: string, name: string) => any;
  format?: (value: any, name: string) => string;
  helperText?: React.ReactNode;
  required?: boolean;
  validate?: (label?: string) => FieldValidator<string>;
  autoComplete?: string;
}

export const validateRequired =
  (t: TFunction, label?: string) =>
  (value: any | undefined): string | undefined =>
    isEmpty(value) ? t('error.field_required', {label}) : undefined;

let focusFirstDecorator: Decorator<any, any> | undefined;
export const focusFirstElement = (): Decorator<any, any> => {
  if (!focusFirstDecorator) {
    focusFirstDecorator = createDecorator();
  }
  return focusFirstDecorator as Decorator<any, any>;
};
