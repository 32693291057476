import React, {useRef, useEffect, useState} from 'react';
import styled from 'styled-components';

import {PictureType} from '../types/common';
import noop from '../utils/noop';
import {RatioSizer, RatioSizerContent} from './global';

export type ResizeMode = 'crop' | 'contain';

type PropsType = {
  picture: PictureType;
  mode?: ResizeMode;
  ratioW?: number;
  ratioH?: number;
} & React.DetailedHTMLProps<
  React.ImgHTMLAttributes<HTMLImageElement>,
  HTMLImageElement
>;

const Cropper = styled.span`
  display: block;
  overflow: hidden;
  position: relative;
`;

const CenteredImg = styled.img`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const MaxedImg = styled(CenteredImg)`
  max-width: 100%;
`;

const imgCache: {[idUrl: string]: {width: number; height: number}} = {};

const Picture = ({
  picture,
  mode,
  className,
  ratioW,
  ratioH,
  ref: _ref,
  id,
  ...props
}: PropsType) => {
  const imgRef = useRef<HTMLImageElement>(null);
  const key = `${id ? `${id}_` : ''}${picture?.source || ''}_${mode}`;
  const [imgSize, setSize] = useState<{
    width?: number;
    height?: number;
    style?: {visibility: 'hidden' | 'visible'};
  }>(() => {
    if (mode) {
      if (imgCache[key]) {
        return imgCache[key];
      }
      return {style: {visibility: 'hidden'}};
    }
    return {};
  });

  useEffect(() => {
    const ref = imgRef.current;
    if (!ref || !mode || imgSize.width != null) {
      return noop;
    }
    let listening = true;
    const onLoad = () => {
      if (ref.naturalWidth) {
        const container = ref.parentElement!;
        const size = container.getBoundingClientRect();
        const ratio = ref.naturalWidth / ref.naturalHeight;
        const sz: {width: number; height: number} =
          ratio >= size.width / size.height === (mode === 'crop')
            ? {
                width: Math.round(size.height * ratio * 100.0) / 100.0,
                height: size.height,
              }
            : {
                width: size.width,
                height: Math.round((size.width / ratio) * 100.0) / 100.0,
              };
        setSize(sz);
        imgCache[key] = sz;
        ref.removeEventListener('load', onLoad);
        listening = false;
      }
    };
    ref.addEventListener('load', onLoad);
    onLoad();
    return () => {
      if (listening) {
        ref.removeEventListener('load', onLoad);
      }
    };
  }, [imgRef.current, key, mode]);

  const imgProps = {
    ...props,
    ...imgSize,
    ref: imgRef,
    src: picture?.source,
    srcSet:
      picture?.sizes
        ?.map((size) => `${size.source} ${size.density}x`)
        ?.join(', ') || undefined,
  };

  if (mode) {
    if (ratioW != null) {
      return (
        <RatioSizer
          id={id}
          as={Cropper}
          className={className}
          width={ratioW}
          height={ratioH}>
          <RatioSizerContent>
            <CenteredImg {...imgProps} />
          </RatioSizerContent>
        </RatioSizer>
      );
    }
    return (
      <Cropper id={id} className={className}>
        <CenteredImg {...imgProps} />
      </Cropper>
    );
  } else if (ratioW != null) {
    return (
      <RatioSizer id={id} className={className} width={ratioW} height={ratioH}>
        <MaxedImg {...imgProps} />
      </RatioSizer>
    );
  }

  return <img {...imgProps} className={className} />;
};

export default React.memo(Picture);
