import * as React from 'react';

function LinkedInLink({
  fill = '#000',
  width = 24,
  ...props
}: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={width}
      height={
        ((typeof width === 'number' ? width : parseInt(width, 10)) / 24) * 23
      }
      viewBox="0 0 24 23"
      {...props}>
      <path
        d="M18.863 17.21h-2.772v-4.462c0-1.122-.402-1.887-1.405-1.887-.766 0-1.222.516-1.423 1.014-.073.178-.09.427-.09.677v4.658H10.4s.036-7.56 0-8.341h2.773v1.18c.368-.568 1.027-1.376 2.498-1.376 1.824 0 3.192 1.192 3.192 3.754v4.783zM7.48 7.73h-.017c-.93 0-1.533-.641-1.533-1.441 0-.82.621-1.442 1.57-1.442.947 0 1.531.623 1.55 1.442 0 .8-.603 1.44-1.57 1.44zm-1.386 9.48h2.772V8.869H6.093v8.34zM12.174 0C5.887 0 .79 5.098.79 11.384c0 6.287 5.097 11.385 11.384 11.385 6.286 0 11.383-5.098 11.383-11.385C23.557 5.098 18.46 0 12.174 0z"
        fill={fill}
        fillRule="evenodd"
      />
    </svg>
  );
}

export default LinkedInLink;
