import {useEffect, useState} from 'react';
import firebase from 'firebase/app';
import {useRouteMatch} from 'react-router-dom';
import {Exhibition, ExhibitionAvailability, PublishStatus} from '../types';
import {isBefore} from 'date-fns';
import {useLogin} from '../contexts/UserContext';
import isExhibitionAdmin from '../utils/isExhibitionAdmin';

const useExhibition = (exhibitionId: string): Exhibition | false | null => {
  const [exhibitionData, setExhibitionData] = useState<
    Exhibition | false | null
  >(null);
  const {url} = useRouteMatch();
  const login = useLogin();

  useEffect(() => {
    if (!exhibitionId) {
      return;
    }

    (async () => {
      const db = firebase.firestore();
      const domain = await db.collection('domains').doc(exhibitionId).get();
      if (domain == null || !domain.exists) {
        setExhibitionData(false);
        return;
      }
      const ref = domain.data()!.ref;
      const fetchData =
        (await ref.get()) as firebase.firestore.DocumentSnapshot<Exhibition>;
      const data = fetchData.data()!;
      const now = new Date();
      const isAvailable =
        data.available_from && isBefore(data.available_from.toDate(), now);
      const isStarted = isBefore(data.start_at.toDate(), now);
      const isEnded = isBefore(data.end_at.toDate(), now);
      const isClosed = isBefore(data.available_to.toDate(), now);
      const availability =
        (!isAvailable && !isStarted) || isClosed
          ? ExhibitionAvailability.closed
          : isEnded
          ? ExhibitionAvailability.ended
          : isStarted
          ? ExhibitionAvailability.open
          : ExhibitionAvailability.preview;
      const exhibition = {
        ...data,
        ref,
        baseUrl: url,
        availability,
        showExhibitors: availability === ExhibitionAvailability.open,
      };
      const isAdmin = isExhibitionAdmin(login.user, exhibition);
      if (isAdmin) {
        exhibition.showExhibitors = true;
      }
      if (!isAdmin && data.status === PublishStatus.hidden) {
        setExhibitionData(false);
      } else {
        setExhibitionData(exhibition);
      }
    })();
  }, [exhibitionId, login]);
  return exhibitionData;
};

export default useExhibition;
