import React, {useLayoutEffect} from 'react';
import {hydrate} from 'react-dom';
import {BrowserRouter} from 'react-router-dom';
import {loadableReady} from '@loadable/component';
import LanguageDetector from 'i18next-browser-languagedetector';
import {useTranslation} from 'react-i18next';
import './firebase';

import i18ninited from './i18n/client';
import App from './App';

const ClientApp = () => {
  const {i18n} = useTranslation();

  useLayoutEffect(() => {
    if (!i18n) {
      return;
    }
    const languageDetector = new LanguageDetector(i18n.services, {
      order: ['querystring', 'localStorage', 'navigator', 'htmlTag'],
      lookupQuerystring: 'lng',
      lookupLocalStorage: 'lng',
      caches: ['localStorage'],
    });
    const detected = languageDetector.detect();
    const detectedLang = Array.isArray(detected) ? detected[0] : detected;
    if (detectedLang !== i18n.languages[0]) {
      i18n.changeLanguage(detectedLang);
    }
    i18n.on('languageChanged', (lng) => {
      if (typeof window !== 'undefined' && window.localStorage) {
        window.localStorage.setItem('lng', lng);
      }
    });
  }, [i18n]);

  return <App />;
};

loadableReady(async () => {
  await i18ninited;
  hydrate(
    <BrowserRouter>
      <ClientApp />
    </BrowserRouter>,
    document.getElementById('root'),
  );
});

if (module.hot) {
  module.hot.accept();
}
