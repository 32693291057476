import {useMemo, ComponentType} from 'react';
import FacebookLink from '../assets/svg/facebook_link';
import LinkedInLink from '../assets/svg/linkedin_link';
import split2 from '../utils/split2';

export const socialIcons: {
  [key: string]: ComponentType<{fill?: string}>;
} = {
  fb: FacebookLink,
  in: LinkedInLink,
};

export interface SocialEntry {
  Icon: React.ComponentType<{fill?: string}>;
  url: string;
}

function getSocials(): SocialEntry[] {
  const elements = (process.env.RAZZLE_SOCIALS ?? '').split(',');
  return elements.map((def) => {
    const [icon, url] = split2(def, ';');
    return {
      Icon: socialIcons[icon],
      url,
    };
  });
}

export default function useSocials() {
  return useMemo(() => getSocials(), []);
}
