import React, {memo} from 'react';
import firebase from 'firebase/app';

import {UserPublicData} from '../types';
import userName from '../utils/userName';
import useFirestoreSnap from '../data/useFirestoreSnap';

const UserName = ({
  user,
  business,
  className,
}: {
  user?:
    | string
    | firebase.firestore.DocumentReference<UserPublicData>
    | UserPublicData;
  business?: boolean;
  className?: string;
}) => {
  const [name] = useFirestoreSnap<UserPublicData, string | undefined>(
    () =>
      typeof user === 'string'
        ? (firebase
            .firestore()
            .doc(
              `users/${user}`,
            ) as firebase.firestore.DocumentReference<UserPublicData>)
        : user instanceof firebase.firestore.DocumentReference
        ? user
        : undefined,
    (snap) => {
      if (!snap || !snap.exists) {
        return undefined;
      }
      const data = snap.data()!;
      return userName(data, business);
    },
    [user, business],
  );

  const value = user?.hasOwnProperty('firstName')
    ? userName(user as UserPublicData, business)
    : name || '–';
  return className ? <span className={className}>{value}</span> : <>{value}</>;
};

export default memo(UserName);
