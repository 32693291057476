import React, {
  createContext,
  useContext,
  ReactNode,
  useCallback,
  useMemo,
} from 'react';
import styled from 'styled-components';
import firebase from 'firebase/app';

import PersistentChat from '../../components/PersistentChat';
import {useLocalStorage} from '../../data/useLocalStorage';
import {
  ChatStatus,
  Exhibition,
  FirestoreUpdate,
  MeetingData,
  MeetingStatus,
  Personnel,
  User,
} from '../../types';
import noop from '../../utils/noop';
import extractUserPublicData from '../../utils/extractUserPublicData';
import {useLogin} from '../../contexts/UserContext';
import {useTranslation} from 'react-i18next';
import FloatingChatWidget from '../../components/FloatingChatWidget';
import ChatList from '../../components/ChatList';

interface CommonProps {
  exhibition: Exhibition;
}

interface PropsType extends CommonProps {
  children?: ReactNode;
}

interface ContainerProps extends CommonProps {
  user: User;
}

export interface ActiveChats {
  [path: string]: ChatStatus;
}

export interface ChatContextType {
  activeChats: ActiveChats;
  upsertChat: (path: string, status?: ChatStatus) => void;
  removeChat: (path: string) => void;
  startChatWith: (personnel: Personnel) => Promise<void>;
}

export const ChatContext = createContext<ChatContextType>({
  activeChats: {},
  upsertChat: noop,
  removeChat: noop,
  // tslint:disable-next-line: no-empty
  startChatWith: async () => {},
});

export const useChatContext = () => useContext(ChatContext);

export const ChatProvider = ({exhibition, children}: PropsType) => {
  const login = useLogin();
  const [activeChats, setActiveChats] = useLocalStorage<ActiveChats>(
    `chat.${exhibition.ref.id}`,
    {},
  );
  const upsertChat = useCallback((path: string, status?: ChatStatus) => {
    setActiveChats((v) => ({...v, [path]: status || {open: true}}));
  }, []);
  const removeChat = useCallback((path: string) => {
    setActiveChats((v) => {
      const r = {...v};
      delete r[path];
      return r;
    });
  }, []);
  const startChatWith = useCallback(
    async (personnel: Personnel) => {
      if (
        !login.user ||
        Object.keys(activeChats).some((p) => p.startsWith(personnel.ref.path))
      ) {
        // Avoid opening chat with the same personnel twice
        return;
      }
      const coll = personnel.ref.collection(
        'meetings',
      ) as firebase.firestore.CollectionReference<FirestoreUpdate<MeetingData>>;
      let ref:
        | firebase.firestore.DocumentReference<FirestoreUpdate<MeetingData>>
        | undefined;
      const find = await coll
        .where('status', '==', MeetingStatus.chat)
        .where('users', 'array-contains', login.user.ref)
        .limit(1)
        .get();
      if (!find.empty) {
        ref = find.docs[0].ref;
      } else {
        ref = await coll.add({
          status: MeetingStatus.chat,
          users: [login.user!.ref],
          queuedAt: firebase.firestore.FieldValue.serverTimestamp(),
          lastMsg: firebase.firestore.FieldValue.serverTimestamp(),
          userData: {
            [login.user!.ref.id]: extractUserPublicData(login.user!),
          },
        });
      }
      setActiveChats((v) => ({...v, [ref!.path]: {open: true}}));
    },
    [activeChats],
  );

  return (
    <ChatContext.Provider
      value={{activeChats, upsertChat, removeChat, startChatWith}}>
      {children}
    </ChatContext.Provider>
  );
};

const Container = styled.section`
  position: fixed;
  bottom: 0;
  right: 10px;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  z-index: 5000;
`;

const ChatContainer = ({exhibition, user}: ContainerProps) => {
  const {activeChats, ...chatContext} = useChatContext();
  const [visible, hidden] = useMemo<[string[], string[]]>(() => {
    const v = [];
    const h = [];
    for (const i of Object.keys(activeChats)) {
      if (activeChats[i].hidden) {
        h.push(i);
      } else {
        v.push(i);
      }
    }
    return [v, h];
  }, [activeChats]);

  return (
    <Container>
      {visible.map((c) => (
        <PersistentChat
          key={c}
          path={c}
          {...activeChats[c]}
          exhibition={exhibition}
          user={user}
        />
      ))}
      <ChatList
        user={user}
        exhibition={exhibition}
        visible={visible}
        hidden={hidden}
        activeChats={activeChats}
        {...chatContext}
      />
    </Container>
  );
};

export default ChatContainer;
