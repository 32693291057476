import firebase from 'firebase/app';
import {RecordRef} from './common';

export interface AdImg {
  width: number;
  height: number;
  src: string;
}
export enum AdScope {
  login_right = 'login_right',
  login_left = 'login_left',
  event = 'event',
  header = 'header',
}

/**
 * Collection `/exhibitions/<exhibitionId>/ads/<exhibitorId>`
 */
export interface AdEntryData {
  name: string;
  scope: AdScope;
  desktop?: AdImg;
  mobile?: AdImg;
  link: string;
  start_at: firebase.firestore.Timestamp;
  end_at: firebase.firestore.Timestamp;
  enabled: boolean;
}

export type AdEntry = RecordRef<AdEntryData>;
