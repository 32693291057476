import React, {useEffect, useState} from 'react';
import {Trans} from 'react-i18next';

import SimpleFloatingMessage from './SimpleFloatingMessage';
import useHostname from '../data/useHostname';

enum State {
  opened,
  closeable,
  closed,
}

const UnsupportedMessage = () => {
  const [unsupported, setUnsupported] = useState<boolean>(false);

  useEffect(() => {
    if (typeof window === 'undefined') {
      return;
    }

    // Opera 8.0+
    const isOpera =
      !!(window as {opera?: any}).opera ||
      !!(
        (window as {opr?: any}).opr &&
        !!(window as typeof window & {opr: {addons?: any}}).opr.addons
      ) ||
      navigator.userAgent.indexOf(' OPR/') >= 0;

    // Internet Explorer 6-11
    const isIE =
      /*@cc_on!@*/ false || !!(document as {documentMode?: any}).documentMode;

    // Edge 20+
    const isEdge = !isIE && !!(window as {StyleMedia?: any}).StyleMedia;

    // Blink engine detection
    const isBlink = isOpera && !!window.CSS;

    setUnsupported(isOpera || isIE || isEdge || isBlink);
  }, []);

  const hostname = useHostname();

  if (unsupported) {
    // Not supported
    return (
      <SimpleFloatingMessage>
        <strong>
          <Trans i18nKey="unsupported.title" />
        </strong>
        <br />
        <Trans i18nKey="unsupported.message" values={{hostname}} />
      </SimpleFloatingMessage>
    );
  }

  return null;
};

export default UnsupportedMessage;
