import React from 'react';
import styled from 'styled-components';
import {DARK_GRAY, WHITE, FONT_BOLD, LIGHT_GRAY} from '../const';

const PlainButton = styled(({size, ...props}) => <button {...props} />)<{
  size?: 'small';
}>`
  -webkit-appearance: none;
  background: ${DARK_GRAY};
  color: ${WHITE};
  text-transform: uppercase;
  font-weight: ${FONT_BOLD};
  font-family: inherit;
  text-align: center;
  border-radius: 20px;
  ${({size}) =>
    size === 'small'
      ? `
    font-size: 10px;
    padding: 5px 15px;
  `
      : `
    font-size: 11px;
    padding: 7px 20px;
  `}
  border: none;
  text-decoration: none;
  display: inline-block;
  &:disabled {
    background: ${LIGHT_GRAY};
  }
`;

export default PlainButton;
