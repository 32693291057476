import React from 'react';
import styled from 'styled-components';

import {DARK_GRAY} from '../const';
import CloseIcon from '../assets/svg/close';

const Button = styled.button`
  -webkit-appearance: none;
  background: none;
  border: none;
  margin: 0;
  padding: 5px;
  position: absolute;
  top: 5px;
  right: 5px;
  width: 33px;
  height: 33px;
  z-index: 103;
`;

const CloseButton = (props: React.ComponentProps<typeof Button>) => (
  <Button {...props}>
    <CloseIcon fill={DARK_GRAY} />
  </Button>
);

export default CloseButton;
