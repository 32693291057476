import {Translated, Falsy, TranslateMap} from '../types';
import i18next from 'i18next';
import get from 'lodash/get';

export default function getTranslatedValue<
  T extends TranslateMap,
  TKey extends keyof T,
>(
  data: Translated<T> | Falsy,
  key: TKey | [TKey],
  i18n: typeof i18next | string,
): T[TKey] | undefined {
  if (!data || !data.lang) {
    return undefined;
  }
  const {lang} = data;
  for (const locale of typeof i18n === 'string' ? [i18n] : i18n.languages) {
    if (lang[locale]) {
      const value = get(lang[locale], key);
      if (value != null) {
        return value;
      }
    }
  }
  return undefined;
}
