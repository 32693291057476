import {
  URLString,
  PictureType,
  AddressData,
  RecordRef,
  UniversalDocRef,
} from './common';
import {ExhibitorData} from './exhibitor';
import firebase from 'firebase/app';
import {PersonnelData} from './personnel';
import {ExhibitionData} from './exhibition';

export type UserID = string;

export enum UserRole {
  visitor = 'user',
  exhibitor = 'exhibitor',
  personnel = 'personnel',
  admin = 'admin',
}

interface Roles<
  E extends UniversalDocRef<ExhibitorData> = firebase.firestore.DocumentReference<ExhibitorData>,
> {
  [key: string]: // key is exhibition id
  | {
        role: UserRole.visitor | UserRole.admin;
      }
    | {
        role: UserRole.exhibitor | UserRole.personnel;
        exhibitor: E;
      };
}

export interface UserPublicData {
  firstName: string;
  lastName: string;
  jobTitle?: string;
  business?: string;
  linkedIn?: URLString;
  photo?: PictureType;
  spokenLangs?: string[];
  mainLang?: string;
}

export const userPublicKeys: (keyof UserPublicData)[] = [
  'firstName',
  'lastName',
  'jobTitle',
  'business',
  'linkedIn',
  'photo',
  'spokenLangs',
];

export interface UserEditableData extends UserPublicData, AddressData {
  email: string;
  fcmTokens?: string[];
  notify?: {
    browser: boolean;
    email: boolean;
  };
  editedProfile?: boolean;
  sector?: string;
}

/**
 * Collection `/users/<UserID>`
 */
export interface UserData<
  E extends UniversalDocRef<ExhibitorData> = firebase.firestore.DocumentReference<ExhibitorData>,
> extends UserEditableData {
  role: UserRole.visitor | UserRole.admin;
  roles?: Roles<E>;
  personnel?: firebase.firestore.DocumentReference<
    PersonnelData<E, firebase.firestore.DocumentReference<UserData<E>>>
  >[];
  invitedEmail?: string;
  last?: {
    login?: firebase.firestore.Timestamp;
    session?: firebase.firestore.Timestamp;
  };
  visited?: string[];
}

// export const copyFromInvitation: (keyof UserData)[] = [
//   'jobTitle',
//   'business',
//   'linkedIn',
//   'photo',
//   'spokenLangs',
//   'address',
//   'city',
//   'zipcode',
//   'state',
//   'country',
//   'phone',
//   'notify',
// ];

export type User = RecordRef<UserData>;

/**
 * Collection `/exhibitions/<exhibitionId>/available-user/<userId>`
 */
export interface AvailableUserData<
  U extends UniversalDocRef<UserData> = firebase.firestore.DocumentReference<UserData>,
> extends UserPublicData {
  user: U;
}

export type AvailableUser = RecordRef<AvailableUserData>;

export enum AvailabilityStatus {
  free = 'free',
  occupied = 'occupied',
  canceled = 'canceled',
  remind = 'remind',
}

/**
 * Collection `/exhibitions/<exhibitionId>/available-user/<userId>/available-slot/<slotId>`
 */
export interface AvailabilitySlotData {
  exhibition: firebase.firestore.DocumentReference<ExhibitionData>;
  from: firebase.firestore.Timestamp;
  to: firebase.firestore.Timestamp;
  status: AvailabilityStatus;
  with?: firebase.firestore.DocumentReference<UserData>;
  personnelRef?: firebase.firestore.DocumentReference<PersonnelData>;
  user?: firebase.firestore.DocumentReference<UserData>;
  mirrorSlot?: firebase.firestore.DocumentReference<AvailabilitySlotData>;
}

export type AvailabilitySlot = RecordRef<AvailabilitySlotData>;
