import {useReducer} from 'react';

interface StateType<R> {
  result?: R;
  loading: boolean;
}

export enum ReducerAction {
  loading,
  inexistent,
  loaded,
}

type reducerArgs<R> =
  | {
      action: ReducerAction.loading | ReducerAction.inexistent;
    }
  | {
      action: ReducerAction.loaded;
      value: R | undefined;
    };

function reducer<R>(state: StateType<R>, arg: reducerArgs<R>): StateType<R> {
  switch (arg.action) {
    case ReducerAction.inexistent:
      return {
        loading: false,
        result: undefined,
      };

    case ReducerAction.loaded:
      return {
        loading: false,
        result: arg.value,
      };

    default:
    case ReducerAction.loading:
      return {
        ...state,
        loading: true,
      };
  }
}

function useFirestoreReducer<R>(): [
  R | undefined,
  boolean,
  (args: reducerArgs<R>) => void,
] {
  const [{result, loading}, dispatch]: [
    StateType<R>,
    (args: reducerArgs<R>) => void,
  ] = useReducer(
    reducer as (state: StateType<R>, arg: reducerArgs<R>) => StateType<R>,
    {
      loading: true,
    },
  );
  return [result, loading, dispatch];
}

export default useFirestoreReducer;
