import * as React from 'react';

function ChatBubbles({
  fill = 'currentColor',
  stroke,
  strokeWidth,
  width = 21,
  height,
  ...props
}: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={width}
      height={typeof width === 'number' ? (width / 21) * 19 : undefined}
      viewBox="0 0 21 19"
      {...props}>
      <defs>
        <path
          id="bubble1"
          d="M13.245 3.897A6.96 6.96 0 006.997.002C3.14 0 0 3.12 0 6.957 0 8 .225 9 .665 9.93L.011 13.2a.597.597 0 00.702.703l3.31-.655c.446.212.907.373 1.38.482-1.061-5.03 2.752-9.752 7.843-9.832"
        />
        <path
          id="bubble2"
          d="M19.688 15.019c.114-.241.213-.487.299-.736h-.022c1.489-4.37-1.642-8.962-6.264-9.186v-.001c-3.977-.182-7.281 3-7.281 6.949a6.964 6.964 0 006.947 6.957 6.884 6.884 0 002.964-.666c3.626.717 3.343.666 3.426.666a.596.596 0 00.585-.713l-.654-3.27z"
        />
        <clipPath id="cbubble1">
          <use xlinkHref="#bubble1" />
        </clipPath>
        <clipPath id="cbubble2">
          <use xlinkHref="#bubble2" />
        </clipPath>
      </defs>
      <g fill={fill} fillRule="evenodd">
        <use
          xlinkHref="#bubble1"
          clipPath="url(#cbubble1)"
          stroke={stroke}
          strokeWidth={
            typeof strokeWidth === 'number' ? strokeWidth * 2 : undefined
          }
        />
        <use
          xlinkHref="#bubble2"
          clipPath="url(#cbubble2)"
          stroke={stroke}
          strokeWidth={
            typeof strokeWidth === 'number' ? strokeWidth * 2 : undefined
          }
        />
      </g>
    </svg>
  );
}

const MemoChatBubbles = React.memo(ChatBubbles);
export default MemoChatBubbles;
