import React from 'react';
import styled from 'styled-components';
import Helmet from 'react-helmet';
import {useTranslation} from 'react-i18next';

import SiteHeader from './SiteHeader';
import SiteFooter from './SiteFooter';
import {MenuEntry} from '../../types';
import CookieBanner from '../CookieBanner';
import UnsupportedMessage from '../UnsupportedMessage';

interface PropsType {
  children: React.ReactNode;
  topMenuEntries?: MenuEntry[];
}

const Page = styled.div`
  display: flex;
  flex-flow: column nowrap;
  width: 100%;
  min-height: 100%;
`;

const PageContent = styled(Page)`
  flex: 1 0 auto;
`;

const SiteChrome = ({children}: PropsType) => {
  const {i18n} = useTranslation();
  return (
    <Page id="page">
      <Helmet
        titleTemplate={process.env.RAZZLE_TITLE_TEMPLATE}
        defaultTitle={process.env.RAZZLE_TITLE_DEFAULT}>
        <html lang={i18n.languages[0]} />
      </Helmet>
      <SiteHeader />
      <PageContent>{children}</PageContent>
      <SiteFooter />
      <CookieBanner />
      <UnsupportedMessage />
    </Page>
  );
};

export default SiteChrome;
