import {InitOptions} from 'i18next';
import format00 from '../utils/format00';
import formatDate from '../utils/formatDate';
import userName from '../utils/userName';

const config: InitOptions = {
  supportedLngs: ['it', 'en'],
  load: 'languageOnly',
  fallbackLng: 'it',
  debug: process.env.NODE_ENV !== 'production',
  react: {
    transSupportBasicHtmlNodes: true,
    transKeepBasicHtmlNodesFor: ['br'],
    useSuspense: false,
  },
  interpolation: {
    format: (value, format, lang): string => {
      if (typeof format === 'string' && format.startsWith('date:')) {
        const fmt = format.substring(5);
        return formatDate(value, fmt, {localeCode: lang});
      } else {
        switch (format) {
          case 'uppercase':
            return value.toUpperCase();
          case 'lowercase':
            return value.toLowerCase();
          case 'ucfirst':
            return value
              ? `${value.charAt(0).toUpperCase()}${value.substring(1)}`
              : '';
          case 'userName':
            if (Array.isArray(value)) {
              return value.map((u) => userName(u)).join(', ');
            }
            return userName(value || {});
          case '00':
            return format00(value);
          case 'join_comma':
            return value.join(', ');
          default:
            return value;
        }
      }
    },
  },
};

export default config;
