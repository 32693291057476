import {MenuEntry} from '../types';
import split2 from './split2';

export default function getLinks(links?: string): MenuEntry[] {
  const elements = (links ?? '').split(',');
  return elements.map((def) => {
    const element = def.split(';');
    const res: MenuEntry = {lang: {}, url: element.pop()!};
    for (const l of element) {
      const [lang, name] = split2(l, '=');
      res.lang[lang] = {name};
    }
    return res;
  });
}
