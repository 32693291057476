import React from 'react';
import styled from 'styled-components';
import {WHITE} from '../const';

interface PropsType {
  className?: string;
  id?: string;
  checked: boolean;
  checkedChildren?: string;
  unCheckedChildren?: string;
  checkedColor?: string;
  unCheckedColor?: string;
  onChange?: (value: boolean) => void;
  disabled?: boolean;
}

const DURATION = '.3s';

const SwitchInner = styled.span`
  color: ${WHITE};
  font-size: 12px;
  position: absolute;
  left: 24px;
  top: 0;
`;

const SwitchButton = styled.button<{
  checked: boolean;
  checkedColor?: string;
  unCheckedColor?: string;
}>`
  position: relative;
  display: inline-block;
  box-sizing: border-box;
  width: 44px;
  height: 22px;
  line-height: 20px;
  padding: 0;
  vertical-align: middle;
  border-radius: 20px 20px;
  border: 1px solid #ccc;
  background-color: ${({unCheckedColor}) => unCheckedColor || '#ccc'};
  cursor: pointer;
  transition: background-color ${DURATION} cubic-bezier(0.35, 0, 0.25, 1);
  will-change: background-color;

  &:after {
    position: absolute;
    width: 18px;
    height: 18px;
    left: 2px;
    top: 1px;
    border-radius: 50% 50%;
    background-color: ${WHITE};
    content: ' ';
    cursor: pointer;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.26);
    transform: translateX(0);
    transition: transform ${DURATION} cubic-bezier(0.35, 0, 0.25, 1);
    will-change: transform;
  }

  ${({checked, checkedColor}) =>
    checked
      ? `
    border: 1px solid #87d068;
    background-color: ${checkedColor || '#87d068'};

    ${SwitchInner} {
      left: 6px;
    }

    &:after{
      transform: translateX(20px);
    }`
      : ''}

  &:focus {
    box-shadow: 0 0 0 2px tint(#2db7f5, 80%);
    outline: none;
  }

  &:disabled {
    cursor: no-drop;
    background: #ccc;
    border-color: #ccc;

    &:after {
      background: #9e9e9e;
      cursor: no-drop;
    }
  }
`;

const Switch = ({
  className,
  checked,
  onChange,
  id,
  checkedChildren,
  unCheckedChildren,
  checkedColor,
  unCheckedColor,
  disabled,
}: PropsType) => (
  <SwitchButton
    className={className}
    checked={checked}
    checkedColor={checkedColor}
    unCheckedColor={unCheckedColor}
    type="button"
    role="switch"
    aria-checked={checked ? 'true' : 'false'}
    id={id}
    onClick={() => onChange && onChange(!checked)}
    disabled={disabled}>
    {checked && checkedChildren && <SwitchInner>{checkedChildren}</SwitchInner>}
    {!checked && unCheckedChildren && (
      <SwitchInner>{unCheckedChildren}</SwitchInner>
    )}
  </SwitchButton>
);

export default Switch;
