export interface AnalyticsExhibitionData {
  exhibition_id?: string;
  exhibition_name?: string;
  exhibition_domain?: string;
}

export interface AnalyticsExhibitorData extends AnalyticsExhibitionData {
  exhibitor_id?: string;
}

export interface AnalyticsPageEventParams extends AnalyticsExhibitionData {
  page_location: string;
  page_path: string;
  page_title?: string;
}

export interface AnalyticsEventSubscribeParams extends AnalyticsExhibitorData {
  event_id: string;
}

export enum CustomAnalyticsEvent {
  SUBSCRIBE_EVENT = 'subscribe_event',
  UNSUBSCRIBE_EVENT = 'unsubscribe_event',
  WATCH_LIVE_EVENT = 'watch_live_event',
  MEETING_START = 'meeting_start',
  MEETING_CREATE = 'meeting_create',
  MEETING_DURATION = 'meeting_duration',
  LEAVE_BUSINESS_CARD = 'leave_business_card',
  GRANT_NOTIFICATION_PERMISSION = 'grant_notification_permission',
  DENY_NOTIFICATION_PERMISSION = 'deny_notification_permission',
  DOWNLOAD_VCARD = 'download_vcard',
  DOWNLOAD_DOCUMENT = 'download_document',
  SHOW_EXHIBITOR_CONTACTS = 'show_exhibitor_contacts',
}
