import React, {useMemo} from 'react';
import styled from 'styled-components';
import {useTranslation} from 'react-i18next';

import {
  WHITE,
  DEVICE,
  PAGE_MARGIN,
  DARK_GRAY,
  PAGE_WIDTH,
  FOOT_OVERLAP,
  FONT_BOLD,
} from '../../const';
import useSocials from '../../data/useSocials';
import getTranslated from '../../utils/getTranslated';
import getLinks from '../../utils/getLinks';
import cutSlash from '../../utils/cutSlash';
import {useExhibitionContext} from '../ExhibitionContext';

const FooterContainer = styled.footer`
  flex: 0 0 auto;
  background: ${DARK_GRAY};
  color: ${WHITE};
  width: 100%;
  padding: 1px ${PAGE_MARGIN}px 60px;
  box-sizing: border-box;
  border-radius: 30px 30px 0 0;
  z-index: 1;
  margin: -${FOOT_OVERLAP}px auto 0;
  a {
    color: inherit;
    text-decoration: none;
  }
  @media ${DEVICE.tablet} {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding-left: 100px;
    padding-right: 100px;
    max-width: ${PAGE_WIDTH - 160 * 2}px;
  }
`;

const FootMenu = styled.ul`
  &,
  li {
    list-style: none;
    padding: 0;
  }
  margin: 60px 0 0;
  flex: 0 1 auto;
  display: block;
`;

const MenuItem = styled.li`
  margin: 0;
  display: block;
  flex: 0 1 auto;
`;

const Credits = styled(MenuItem)`
  margin-top: 15px;
`;

const MenuLink = styled.a`
  display: block;
  padding: 5px 0;
`;

const SocialLink = styled.a`
  display: inline-block;
  padding: 5px;
  &:first-child {
    margin-left: -5px;
  }
`;

const Data = styled.div`
  margin-top: 60px;
  flex: 0 1 auto;
  line-height: 1.4;
  @media ${DEVICE.tablet} {
    margin-left: auto;
  }
`;

const Institution = styled.p`
  font-size: 27px;
  font-weight: ${FONT_BOLD};
  margin: 0;
`;

const Owner = styled(Institution)`
  font-size: 17px;
`;

const Address = styled(Owner)`
  font-weight: normal;
`;

const FiscalData = styled.p`
  margin: 1em 0 0;
`;

const SiteFooter = () => {
  const exhibition = useExhibitionContext();
  const socials = useSocials();
  const links = useMemo(() => getLinks(process.env.RAZZLE_BOTTOMLINKS), []);
  const {i18n} = useTranslation();
  return (
    <FooterContainer>
      <FootMenu>
        <MenuItem>
          {socials.map((entry) => (
            <SocialLink
              key={entry.url}
              href={entry.url}
              target="_blank"
              rel="nofollow noopener">
              <entry.Icon fill={WHITE} />
            </SocialLink>
          ))}
        </MenuItem>
        {links.map((entry) => {
          const url =
            typeof entry.url === 'string'
              ? entry.url
              : entry.url(
                  exhibition
                    ? cutSlash(exhibition.baseUrl)
                    : process.env.RAZZLE_ORG_WEBSITE!,
                );
          return (
            <MenuItem key={url}>
              <MenuLink href={url}>
                {getTranslated(entry, i18n)?.name || ''}
              </MenuLink>
            </MenuItem>
          );
        })}
        <Credits>
          <a
            href="https://www.bithouseweb.com/"
            rel="nofollow noopener"
            target="_blank">
            Powered by <strong>bitHOUSEweb</strong>
          </a>
        </Credits>
      </FootMenu>
      <Data>
        <Institution>{process.env.RAZZLE_ORG_NAME}</Institution>
        <Owner>{process.env.RAZZLE_ORG_OWNER || '\u00A0'}</Owner>
        <Address>{process.env.RAZZLE_ORG_ADDRESS}</Address>
        <FiscalData
          dangerouslySetInnerHTML={{
            __html: process.env.RAZZLE_ORG_FISCAL ?? '',
          }}
        />
      </Data>
    </FooterContainer>
  );
};

export default SiteFooter;
