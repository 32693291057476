import React from 'react';
import styled from 'styled-components';

import Live from '../assets/svg/live';
import {BLACK, WHITE} from '../const';
import {ThemeType} from '../types';

interface PropsType {
  theme: ThemeType;
  className?: string;
  children: React.ReactElement;
}

const Row = styled.div`
  display: flex;
  align-items: center;
`;

const Pipe = styled.div<{theme: ThemeType}>`
  flex: 0 0 1px;
  height: 50px;
  margin: 0 12px;
  background: ${({theme}) => (theme === 'light' ? BLACK : WHITE)};
`;

const LiveBadge = styled(Live)`
  flex: 0 0 83px;
`;

const PipeLive = ({children, theme, className}: PropsType) => {
  if (!process.env.RAZZLE_PIPE_LIVE) {
    return children;
  }
  return (
    <Row className={className}>
      {children}
      <Pipe theme={theme} />
      <LiveBadge mode={theme} />
    </Row>
  );
};

export default PipeLive;
