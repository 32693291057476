import {DependencyList} from 'react';
import firebase from 'firebase/app';
import useFirestoreSnap from './useFirestoreSnap';

const useFirestoreDoc = <D>(
  ref:
    | firebase.firestore.DocumentReference<D>
    | string
    | undefined
    | null
    | (() => firebase.firestore.DocumentReference<D> | undefined | null),
  deps: DependencyList,
  live = false,
): [D | undefined, boolean] =>
  useFirestoreSnap(ref, (snap) => snap.data(), deps, live);

export default useFirestoreDoc;
