import {ThemeType} from 'aws-sdk/clients/quicksight';
import * as React from 'react';
import {ACCENT_RED, WHITE} from '../../const';

function Live({
  mode = 'light',
  ...props
}: React.SVGProps<SVGSVGElement> & {mode?: ThemeType}) {
  const [backColor, textColor] =
    mode === 'light' ? [ACCENT_RED, WHITE] : [WHITE, ACCENT_RED];
  return (
    <svg width={83} height={31} viewBox="0 0 83 31" {...props}>
      <g fill="none" fillRule="evenodd">
        <path
          d="M77.7 30.86H4.795A4.809 4.809 0 010 26.064V4.795A4.809 4.809 0 014.795 0H77.7a4.808 4.808 0 014.794 4.794v21.271A4.808 4.808 0 0177.7 30.86"
          fill={backColor}
        />
        <path
          fill={textColor}
          d="M9.732 22.012h8.303v2.563H6.555V6.285h3.177zM20.662 24.574h3.165V6.284h-3.165zM34.178 20.593l4.647-14.308h3.493l-6.582 18.29h-3.078L26.1 6.285h3.479zM55.068 16.41h-7.512v5.603h8.782v2.562h-11.96V6.285h11.87v2.563h-8.692v5.05h7.512z"
        />
        <path
          d="M76.99 15.43a5.751 5.751 0 11-11.503 0 5.751 5.751 0 0111.503 0z"
          stroke={textColor}
          strokeWidth={1.213}
        />
        <path
          d="M74.534 15.43a3.295 3.295 0 11-6.59 0 3.295 3.295 0 016.59 0"
          fill={textColor}
        />
      </g>
    </svg>
  );
}

const MemoLive = React.memo(Live);
export default MemoLive;
