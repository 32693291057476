import React from 'react';
import styled from 'styled-components';

import FloatingMessage from './FloatingMessage';
import {Message} from './global';

interface PropsType {
  children: React.ReactNode;
  buttons?: React.ReactNode;
  closeable?: boolean;
  onRequestClose?: () => void;
}

const FloatingWindow = styled(FloatingMessage)`
  text-align: center;
`;

const SimpleFloatingMessage = ({
  children,
  buttons,
  closeable,
  onRequestClose,
}: PropsType) => {
  return (
    <FloatingWindow closeable={closeable} onRequestClose={onRequestClose}>
      <Message>{children}</Message>
      {buttons}
    </FloatingWindow>
  );
};
export default SimpleFloatingMessage;
