import * as React from 'react';
import {WHITE} from '../../const';

function UserIcon({fill = WHITE, ...props}: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 600 600" {...props}>
      <g fill={fill} fillRule="evenodd">
        <path d="M342.11 390.016h-84.22l-3.263.027c-53.705.901-102.266 24.031-137.385 60.816C82.167 487.597 60.5 537.956 60.5 593.398a7.59 7.59 0 003.046 6.104h472.908a7.59 7.59 0 003.046-6.103c0-56.051-22.145-106.905-57.9-143.744-35.74-36.821-85.078-59.639-139.49-59.639z" />
        <ellipse
          fillRule="nonzero"
          cx={292.679}
          cy={251.832}
          rx={106.776}
          ry={109.832}
        />
      </g>
    </svg>
  );
}

export default UserIcon;
