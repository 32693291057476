import * as React from 'react';

function LoadingSvg({
  width = 147,
  height,
  ...props
}: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      style={{
        margin: 'auto',
      }}
      width={width}
      height={width}
      viewBox="0 0 100 100"
      preserveAspectRatio="xMidYMid"
      display="block"
      {...props}>
      <circle
        cx={50}
        cy={50}
        r={0}
        fill="none"
        stroke="#e1001a"
        strokeWidth={3}>
        <animate
          attributeName="r"
          repeatCount="indefinite"
          dur="1s"
          values="0;40"
          keyTimes="0;1"
          keySplines="0 0.2 0.8 1"
          calcMode="spline"
          begin="-0.5s"
        />
        <animate
          attributeName="opacity"
          repeatCount="indefinite"
          dur="1s"
          values="1;0"
          keyTimes="0;1"
          keySplines="0.2 0 0.8 1"
          calcMode="spline"
          begin="-0.5s"
        />
      </circle>
      <circle
        cx={50}
        cy={50}
        r={0}
        fill="none"
        stroke="#1a171b"
        strokeWidth={3}>
        <animate
          attributeName="r"
          repeatCount="indefinite"
          dur="1s"
          values="0;40"
          keyTimes="0;1"
          keySplines="0 0.2 0.8 1"
          calcMode="spline"
        />
        <animate
          attributeName="opacity"
          repeatCount="indefinite"
          dur="1s"
          values="1;0"
          keyTimes="0;1"
          keySplines="0.2 0 0.8 1"
          calcMode="spline"
        />
      </circle>
    </svg>
  );
}

export default LoadingSvg;
