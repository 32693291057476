import React from 'react';
import styled from 'styled-components';
import LoadingSvg from '../assets/svg/loading';

const Page = styled.div`
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export default ({width, className}: {width?: number; className?: string}) => (
  <Page className={className}>
    <LoadingSvg width={width} />
  </Page>
);
