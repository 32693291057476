import * as React from 'react';

function FacebookLink({
  fill = '#000',
  ...props
}: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={23} height={23} viewBox="0 0 23 23" {...props}>
      <path
        d="M22.768 11.384C22.768 5.096 17.672 0 11.384 0S0 5.096 0 11.384s5.097 11.384 11.385 11.384c.067 0 .133 0 .2-.004v-8.859H9.137v-2.85h2.445V8.956c0-2.432 1.486-3.758 3.657-3.758 1.04 0 1.933.076 2.192.112v2.543h-1.494c-1.179 0-1.41.56-1.41 1.383v1.815h2.824l-.369 2.85h-2.455v8.427c4.758-1.365 8.24-5.745 8.24-10.944"
        fill={fill}
        fillRule="evenodd"
      />
    </svg>
  );
}

export default FacebookLink;
