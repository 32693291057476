import React, {createContext, useCallback, useContext, useState} from 'react';
import styled from 'styled-components';
import {Link} from 'react-router-dom';
import {useTranslation, Trans} from 'react-i18next';

import {WHITE, FONT_BOLD, BLACK, LIGHT_GRAY, ACCENT_RED} from '../const';
import {useExhibitionContext} from '../components/ExhibitionContext';
import {useLogin} from './UserContext';
import {useDeviceClass} from './DeviceClassContext';
import userName from '../utils/userName';
import noop from '../utils/noop';
import cutSlash from '../utils/cutSlash';
import SlideMenu from '../components/SlideMenu';

interface PropsType {
  children: React.ReactNode;
}

type ContextType = [boolean, (visible: boolean) => void, boolean];

const MenuItem = styled.div`
  font-size: 16px;
  display: block;
  padding: 15px 20px;
  border-bottom: 1px solid ${LIGHT_GRAY};
  color: ${BLACK};
  text-decoration: none;
`;

const AnchorItem = styled(MenuItem)`
  text-transform: uppercase;
  font-weight: ${FONT_BOLD};
  -webkit-appearance: none;
  border-width: 0 0 1px;
  background: none;
  display: block;
  text-align: left;
  width: 100%;
  box-sizing: border-box;
  &:active {
    background: ${({theme: {accentColor}}) => accentColor || ACCENT_RED};
    color: ${WHITE};
  }
`;

const UserName = styled.div`
  text-transform: uppercase;
  font-weight: ${FONT_BOLD};
`;

const UserMenuContext = createContext<ContextType>([false, noop, false]);

export const UserMenuProvider = ({children}: PropsType) => {
  const login = useLogin();
  const exhibition = useExhibitionContext();
  const [menuVisible, setMenuVisible] = useState<boolean>(false);
  const device = useDeviceClass();
  const menuInPage = device === 'mobile';
  const {t} = useTranslation();

  const closeMenu = useCallback(() => setMenuVisible(false), []);

  const name = login.user && userName(login.user);

  return (
    <UserMenuContext.Provider value={[menuVisible, setMenuVisible, menuInPage]}>
      {menuInPage && (
        <SlideMenu
          isOpen={menuVisible}
          onRequestClose={() => setMenuVisible(false)}>
          {name && (
            <MenuItem>
              <Trans
                i18nKey="user_widget.private_area"
                values={{name}}
                components={{name: <UserName />}}
              />
            </MenuItem>
          )}
          {login.valid && (
            <AnchorItem as={Link} to="/profile" onClick={closeMenu}>
              {t('user_widget.profile')}
            </AnchorItem>
          )}
          {login.user ? (
            <AnchorItem
              as="button"
              type="button"
              onClick={() => {
                login.logout();
                closeMenu();
              }}>
              {t('common.logout')}
            </AnchorItem>
          ) : (
            <AnchorItem
              as={Link}
              to={`${cutSlash(exhibition?.baseUrl || '')}/login`}
              onClick={closeMenu}>
              {t('common.login_register')}
            </AnchorItem>
          )}
        </SlideMenu>
      )}
      {children}
    </UserMenuContext.Provider>
  );
};

export const useUserMenu = () => useContext(UserMenuContext);
