import React from 'react';
import styled from 'styled-components';
import {WHITE} from '../const';

interface PropsType {
  children: React.ReactNode;
  isOpen: boolean;
  onRequestClose: () => void;
}

const DivNotVisible = ({
  visible,
  ...props
}: React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
> & {visible: boolean}) => <div {...props} />;

const Overlay = styled(DivNotVisible)`
  position: fixed;
  z-index: 1000;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  transition: opacity 0.3s ease 0s;
  ${({visible}) =>
    visible
      ? 'opacity: 1;'
      : 'opacity: 0; transform: translate3d(100%, 0px, 0px);'}
`;

const MenuWrap = styled(DivNotVisible)`
  position: fixed;
  right: 0px;
  z-index: 1100;
  width: 300px;
  height: 100%;
  transition: all 0.5s ease 0s;
  ${({visible}) => (visible ? '' : 'transform: translate3d(100%, 0px, 0px);')}
`;

const Menu = styled.div`
  height: 100%;
  box-sizing: border-box;
  overflow: auto;
  background: ${WHITE};
`;

const MenuList = styled.nav`
  height: 100%;
  padding: 0px;
  margin: 0px;
  list-style: none;
`;

const SlideMenu = ({children, isOpen, onRequestClose}: PropsType) => {
  return (
    <>
      <Overlay visible={isOpen} onClick={onRequestClose} />
      <MenuWrap visible={isOpen} aria-hidden={isOpen ? 'false' : 'true'}>
        <Menu>
          <MenuList>{children}</MenuList>
        </Menu>
      </MenuWrap>
    </>
  );
};

export default SlideMenu;
