import * as React from 'react';

function SendIcon({
  width = 18,
  height,
  ...props
}: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={width}
      height={width}
      viewBox="0 0 18 18"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <g fill="#9C9D9F" fillRule="evenodd">
        <path d="M17.074 7.132L2.73.192A1.901 1.901 0 00.137 2.61l2.24 5.599h15.658a1.884 1.884 0 00-.961-1.077M2.377 9.476l-2.24 5.602a1.905 1.905 0 002.593 2.42l14.344-6.945c.45-.218.796-.604.961-1.077H2.377z" />
      </g>
    </svg>
  );
}

export default SendIcon;
