import React from 'react';
import styled from 'styled-components';
import firebase from 'firebase/app';

import {DEVICE, DARK_GRAY, WHITE} from '../const';

interface PropsType extends firebase.messaging.NotificationPayload {
  link?: string;
  click_action?: string;
  visible?: boolean;
  className?: string;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
}

const Notification = styled(({visible, ...props}) => <div {...props} />)<{
  visible: boolean | undefined;
}>`
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background: ${WHITE};
  border: 1px solid ${DARK_GRAY};
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  border-radius: 0 0 10px 10px;
  padding: 10px;
  display: flex;
  transition: transform 0.3s ease-out;
  transform: translateY(${({visible}) => (visible ? '0' : '-120%')});
  text-decoration: none;
  color: inherit;
  z-index: 1001;

  @media ${DEVICE.tablet} {
    width: 300px;
    min-height: 52px;
    top: 20px;
    left: auto;
    right: 20px;
    border-radius: 10px;
    box-sizing: border-box;
    transform: translateX(${({visible}) => (visible ? '0' : '120%')});
  }
`;

const Icon = styled.div<{image: string}>`
  flex: 0 0 32px;
  height: 32px;
  background: url(${({image}) => image}) 50% 50% no-repeat;
  background-size: contain;
`;

const Content = styled.div`
  flex: 1 1 100%;
  box-sizing: border-box;
  ${Icon} + & {
    padding-left: 20px;
  }
`;

const Title = styled.h2`
  font-weight: bold;
  font-size: 14x;
  text-align: left;
  margin: 0;
`;

const Text = styled.p`
  margin: 5px 0 0;
`;

const NotificationBadge = ({
  image,
  title,
  body,
  link,
  click_action,
  visible,
  className,
  onClick,
}: PropsType) => {
  const props:
    | {}
    | {as: 'a'; href: string}
    | {
        as: 'button';
        type: 'button';
        onClick: React.MouseEventHandler<HTMLButtonElement>;
      } = onClick
    ? {as: 'button', type: 'button', onClick}
    : link || click_action
    ? {as: 'a', href: link || click_action}
    : {};

  return (
    <Notification {...props} visible={visible} className={className}>
      {image ? <Icon image={image} /> : <Icon image="/apple-icon.png" />}
      <Content>
        <Title>{title}</Title>
        {body && <Text>{body}</Text>}
      </Content>
    </Notification>
  );
};

NotificationBadge.defaultProps = {
  visible: true,
};

export default NotificationBadge;
