import React, {createContext, useContext, useEffect, useState} from 'react';
import useLatest from 'use-latest';
import {DeviceClass, DEVICE_SIZE} from '../const';
import supportsPassive from '../utils/supportsPassive';

interface PropsType {
  children: React.ReactNode;
}

export const DeviceClassContext = createContext<DeviceClass | undefined>(
  undefined,
);

export function DeviceClassProvider({children}: PropsType) {
  const [deviceClass, setDeviceClass] = useState<DeviceClass | undefined>();
  const latest = useLatest<DeviceClass | undefined>(deviceClass);

  useEffect(() => {
    function handleResize() {
      const dc = (Object.keys(DEVICE_SIZE).find(
        (c) => DEVICE_SIZE[c as keyof typeof DEVICE_SIZE] > window.innerWidth,
      ) || 'desktop') as DeviceClass;
      if (latest.current !== dc) {
        setDeviceClass(dc);
      }
    }

    // Add event listener
    const param = supportsPassive() ? {passive: true} : false;
    window.addEventListener('resize', handleResize, param);

    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <DeviceClassContext.Provider value={deviceClass}>
      {children}
    </DeviceClassContext.Provider>
  );
}

export const useDeviceClass = () => useContext(DeviceClassContext);
