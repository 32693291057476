import {useLocation} from 'react-router-dom';

function useHostname(): string {
  const location = useLocation();
  return typeof window !== 'undefined'
    ? window.location.host
    : location.hasOwnProperty('hostname')
    ? (location as typeof location & {hostname: string}).hostname
    : '';
}

export default useHostname;
