import firebase from 'firebase/app';
import flatten from 'lodash/flatten';
import reportSnapshotError from './reportSnapshotError';

import noop from './noop';

function getAllDocs<T extends object>(
  refs: firebase.firestore.DocumentReference<T>[],
  onSnapshot: (docs: firebase.firestore.QueryDocumentSnapshot<T>[]) => void,
): () => void {
  if (refs.length === 0) {
    onSnapshot([]);
    return noop;
  }
  const collectionGroup = refs[0].firestore.collectionGroup(
    refs[0].parent.id,
  ) as firebase.firestore.Query<T>;
  const cancel: (() => void)[] = [];
  const queryRes: (
    | firebase.firestore.QueryDocumentSnapshot<T>[]
    | undefined
  )[] = [];
  for (let i = 0, q = 0; i < refs.length; i += 10, ++q) {
    console.log(refs[i]);
    queryRes.push(undefined);
    cancel.push(
      collectionGroup
        .where(
          firebase.firestore.FieldPath.documentId(),
          'in',
          refs.slice(i, i + 10).map((p) => p.path),
        )
        .onSnapshot((snap) => {
          queryRes[q] = snap.docs;
          if (queryRes.every((r) => r != null)) {
            onSnapshot(
              flatten(
                queryRes as firebase.firestore.QueryDocumentSnapshot<T>[][],
              ),
            );
          }
        }, reportSnapshotError(refs[i].parent)),
    );
  }
  return () => {
    cancel.forEach((c) => c());
  };
}

export default getAllDocs;
