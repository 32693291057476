import React from 'react';
import {Link} from 'react-router-dom';
import styled from 'styled-components';

import {
  BLACK,
  DEVICE,
  DeviceClass,
  FONT_BOLD,
  LIGHT_GRAY,
  MILD_GRAY,
  PAGE_MARGIN,
  PAGE_WIDTH,
  WHITE,
} from '../const';
import {Exhibition, Falsy} from '../types';
import cutSlash from '../utils/cutSlash';
import PlainButton from './PlainButton';

export const Sizer = styled.div<{background?: string}>`
  position: relative;
  width: 100%;
  max-width: ${PAGE_WIDTH}px;
  margin: 0 auto;
  box-sizing: border-box;
  padding: 0 ${PAGE_MARGIN}px;
  @media (min-width: ${PAGE_WIDTH + PAGE_MARGIN}px) {
    padding: 0;
  }
  ${({background}) => (background != null ? `background: ${background};` : '')}
`;

export const Message = styled.p`
  font-size: 16px;
  text-align: center;
`;

export const BigMessage = styled(Message)`
  font-size: 24px;
`;

export const RatioSizer = styled((props) => <div {...props} />)<{
  width: number;
  height: number;
}>`
  position: relative;
  &:before {
    content: '';
    display: block;
    width: 100%;
    height: 0;
    padding-bottom: ${({width, height}) =>
      Math.round((height / width) * 10000.0) / 100.0}%;
  }
`;

export const VerticalRatioSizer = styled((props) => <RatioSizer {...props} />)<{
  width: number;
  height: number;
}>`
  height: 100%;
  &:before {
    width: 100%;
    height: 100%;
    padding-bottom: 0;
  }
`;

export const RatioSizerContent = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

export const ShowOn = styled.div<{device: DeviceClass; display?: string}>`
  ${({device, display = 'block'}) => `
  display: none;
  @media ${DEVICE[device]} {
    display: ${display};
  }
`}
`;

export const HideOn = styled.div<{device: DeviceClass; display?: string}>`
  ${({device, display = 'block'}) => `
  display: ${display};
  @media ${DEVICE[device]} {
    display: none;
  }
`}
`;

export const FullWidth = styled.div`
  margin: 0 -${PAGE_MARGIN}px;
  @media ${DEVICE.tablet} {
    margin-left: 0;
    margin-right: 0;
  }
`;

export const LinkButton = styled.button`
  -webkit-appearance: none;
  border: none;
  background: none;
  padding: 0;
  display: inline;
  margin: 0;
  font-size: inherit;
  font-family: inherit;
  text-decoration: underline;
  cursor: pointer;
  font-weight: inherit;
`;

export const BlockTitle = styled.h2`
  font-size: 24px;
  font-weight: ${FONT_BOLD};
  text-transform: uppercase;
  margin: 20px 0 10px;
  flex: 0 0 auto;
`;

export const TitleLine = styled.div`
  display: flex;
  margin: 40px 0 10px;
  align-items: center;
  justify-content: space-between;
  ${BlockTitle} {
    margin: 0;
  }
`;

export const TransparentButton = styled.button`
  -webkit-appearance: none;
  background: none;
  color: inherit;
  font-size: inherit;
  font-weight: inherit;
  margin: 0;
  padding: 5px;
  border: none;
`;

export const NoResults = styled.p`
  margin: 0.5em 15px 1.5em;
  color: ${MILD_GRAY};
  font-size: 14px;
`;

export const Frame = styled.div`
  padding: 10px;
  border: 1px solid ${LIGHT_GRAY};
  font-size: 12px;
  line-height: 1.2;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.5);
  a {
    color: ${({theme: {accentColor}}) => accentColor};
    text-decoration: underline;
  }
  .username {
    font-size: 13px;
  }
`;
