import React, {useEffect, useState} from 'react';
import {
  Route,
  Switch,
  useRouteMatch,
  useParams,
  Redirect,
  useLocation,
  useHistory,
} from 'react-router-dom';
import loadable from '@loadable/component';
import firebase from 'firebase/app';

import NotFound from '../NotFound';
import {ExhibitionProvider} from '../../components/ExhibitionContext';
import {UserProvider, useLogin} from '../../contexts/UserContext';
import useExhibition from '../../data/useExhibition';
import NotificationListener from '../../contexts/NotificationListener';
import isExhibitionAdmin from '../../utils/isExhibitionAdmin';
import useTranslated from '../../utils/useTranslated';
import cutSlash from '../../utils/cutSlash';
import useHostname from '../../data/useHostname';
import LoadingPage from '../../components/LoadingPage';
import {UserMenuProvider} from '../../contexts/UserMenuContext';
import {useTidio} from '../../data/useTidio';
import {ThemeProvider} from 'styled-components';
import {ExhibitionAvailability} from '../../types';
import ChatContainer, {ChatProvider} from './ChatContainer';

function getHref(pathname: string, search: string) {
  if (!search) {
    return pathname;
  }
  return `${pathname}?${search}`;
}

const LoadableHome = loadable(
  () => import(/* webpackChunkName: "home" */ './Home'),
  {cacheKey: () => 'home'},
);
const LoadableLogin = loadable(
  () => import(/* webpackChunkName: "login" */ './Login'),
  {cacheKey: () => 'login'},
);
const LoadableInviteAccept = loadable(
  () => import(/* webpackChunkName: "inviteaccept" */ './InviteAccept'),
  {cacheKey: () => 'inviteaccept'},
);
const LoadableRegister = loadable(
  () => import(/* webpackChunkName: "register" */ './Register'),
  {cacheKey: () => 'register'},
);
const LoadablePrivacy = loadable(
  () => import(/* webpackChunkName: "privacy" */ './Privacy'),
  {cacheKey: () => 'privacy'},
);
const LoadableProfilePage = loadable(
  () => import(/* webpackChunkName: "profilepage" */ './ProfilePage'),
  {cacheKey: () => 'profilepage'},
);
const LoadableMeetingCalendarPage =
  process.env.RAZZLE_FEATURES &&
  process.env.RAZZLE_FEATURES.indexOf('user-avail') !== -1
    ? loadable(
        () =>
          import(/* webpackChunkName: "meetingcalendar" */ './MeetingCalendar'),
        {cacheKey: () => 'meetingcalendar'},
      )
    : undefined;
const LoadableMeetingRequestsPage =
  process.env.RAZZLE_FEATURES &&
  process.env.RAZZLE_FEATURES.indexOf('user-avail') !== -1
    ? loadable(
        () =>
          import(/* webpackChunkName: "meetingrequests" */ './MeetingRequests'),
        {cacheKey: () => 'meetingrequests'},
      )
    : undefined;
const LoadableEvents = loadable(
  () => import(/* webpackChunkName: "events" */ './Events'),
  {cacheKey: () => 'events'},
);
const LoadableEventPage = loadable(
  () => import(/* webpackChunkName: "eventpage" */ './EventPage'),
  {cacheKey: () => 'eventpage'},
);
const LoadableExhibitorPage = loadable(
  () => import(/* webpackChunkName: "exhibitorpage" */ './ExhibitorPage'),
  {cacheKey: () => 'exhibitorpage'},
);
const LoadableChat = loadable(
  () => import(/* webpackChunkName: "chat" */ './Chat'),
  {cacheKey: () => 'chat'},
);
const LoadableCallList = loadable(
  () => import(/* webpackChunkName: "calllist" */ './CallList'),
  {cacheKey: () => 'calllist'},
);
const LoadableBroadcaster = loadable(
  () => import(/* webpackChunkName: "broadcaster" */ './Broadcaster'),
  {cacheKey: () => 'broadcaster'},
);

interface PageState {
  redir?: string;
}

interface PropsType {
  children?: React.ReactNode;
}

const ExhibitionContainer = ({children}: PropsType) => {
  const {path} = useRouteMatch();
  const history = useHistory();
  const {pathname, search, state} = useLocation<PageState>();
  const params = useParams<{exhibition?: string}>();
  const hostname = useHostname();
  const exhibition = params?.exhibition != null ? params.exhibition : hostname;
  const data = useExhibition(exhibition);
  const login = useLogin();
  const isAdmin = isExhibitionAdmin(login.user, data);
  const [redirected, setRedirected] = useState<boolean>(false);
  const translated = useTranslated(data);

  useEffect(() => {
    if (!data) {
      return;
    }
    if (!redirected && login.valid && !login.user!.editedProfile) {
      setRedirected(true);
      if (
        (pathname === '/login' && /[?&]invite=.{5,}(&|$)/.test(search)) ||
        (pathname === '/profile' && /[?&]edit=1(&|$)/.test(search))
      ) {
        return;
      }
      history.push(`${cutSlash(data.baseUrl)}/profile?edit=1`);
    } else if (redirected && !login.user) {
      setRedirected(false);
    }
  }, [redirected, history, login.user, data]);

  useEffect(() => {
    if (!login.user || !data || login.user.visited?.includes(data.ref.id)) {
      return;
    }
    login.user.ref.update({
      visited: firebase.firestore.FieldValue.arrayUnion(data.ref.id),
    });
  }, [login.user, data]);
  useTidio(
    (tidioApi) => {
      const {user} = login;
      if (!user || !translated) {
        return;
      }
      tidioApi.setContactProperties({
        business: user.business,
        exhibition: translated?.name,
      });
    },
    [login?.user, translated?.name],
  );

  const basePath = cutSlash(path);

  if (data === null) {
    return <LoadingPage />;
  }
  if (!isAdmin && data && data.availability === ExhibitionAvailability.closed) {
    if (data.website) {
      location.href = data.website;
      return null;
    }
    return (
      <ExhibitionProvider value={data}>
        <NotificationListener exhibition={data}>
          <NotFound />
        </NotificationListener>
      </ExhibitionProvider>
    );
  }
  if (data === false) {
    return <NotFound />;
  }

  const basicRoutes = [
    <Route key="home" exact={true} path={`${path}`}>
      <LoadableHome fallback={<LoadingPage />} />
    </Route>,
    <Route key="broadcaster" path={`${basePath}/__broadcast/:meetingId`}>
      <LoadableBroadcaster fallback={<LoadingPage />} />
    </Route>,
    <Route key="privacy" exact={true} path={`${basePath}/privacy-policy`}>
      <LoadablePrivacy fallback={<LoadingPage />} />
    </Route>,
    <Route key="eventpage" exact={true} path={`${basePath}/events/:eventId`}>
      <LoadableEventPage fallback={<LoadingPage />} />
    </Route>,
  ];

  if (login.user == null || !login.valid) {
    return (
      <ExhibitionProvider value={data}>
        <NotificationListener exhibition={data}>
          <ThemeProvider theme={data}>
            {children}
            <Switch>
              {basicRoutes}
              <Route exact={true} path={`${basePath}/login`}>
                <LoadableLogin fallback={<LoadingPage />} />
              </Route>
              <Route exact={true} path={`${basePath}/register`}>
                <LoadableRegister fallback={<LoadingPage />} />
              </Route>
              <Route path="*">
                <Redirect
                  to={{
                    pathname:
                      login.user == null
                        ? `${cutSlash(data.baseUrl)}/login`
                        : data.baseUrl,
                    state: {redir: getHref(pathname, search)},
                  }}
                />
              </Route>
            </Switch>
          </ThemeProvider>
        </NotificationListener>
      </ExhibitionProvider>
    );
  }

  const hasUserAvail =
    process.env.RAZZLE_FEATURES &&
    process.env.RAZZLE_FEATURES.indexOf('user-avail') !== -1;

  return (
    <ExhibitionProvider value={data}>
      <ChatProvider exhibition={data}>
        <NotificationListener exhibition={data}>
          <ThemeProvider theme={data}>
            {children}
            <Switch>
              {basicRoutes}
              <Route exact={true} path={`${basePath}/profile`}>
                <LoadableProfilePage fallback={<LoadingPage />} />
              </Route>
              {hasUserAvail && LoadableMeetingCalendarPage && (
                <Route exact={true} path={`${basePath}/meetings`}>
                  <LoadableMeetingCalendarPage fallback={<LoadingPage />} />
                </Route>
              )}
              {hasUserAvail && LoadableMeetingRequestsPage && (
                <Route exact={true} path={`${basePath}/meeting-requests`}>
                  <LoadableMeetingRequestsPage fallback={<LoadingPage />} />
                </Route>
              )}
              <Route exact={true} path={`${basePath}/events`}>
                <LoadableEvents fallback={<LoadingPage />} />
              </Route>
              <Route exact={true} path={`${basePath}/register`}>
                <Redirect to={data.baseUrl} />
              </Route>
              <Route exact={true} path={`${basePath}/login`}>
                <LoadableInviteAccept
                  redir={state?.redir}
                  fallback={<LoadingPage />}
                />
              </Route>
              <Route exact={true} path={`${basePath}/:exhibitor`}>
                <LoadableExhibitorPage fallback={<LoadingPage />} />
              </Route>
              <Route
                exact={true}
                path={`${basePath}/:exhibitor/chat/:personnel`}>
                <LoadableChat fallback={<LoadingPage />} />
              </Route>
              <Route
                exact={true}
                path={`${basePath}/:exhibitor/chat/:personnel/history`}>
                <LoadableCallList fallback={<LoadingPage />} />
              </Route>
              <Route path="*" component={NotFound} />
            </Switch>
            <ChatContainer exhibition={data} user={login.user} />
          </ThemeProvider>
        </NotificationListener>
      </ChatProvider>
    </ExhibitionProvider>
  );
};

export default (props: PropsType) => (
  <UserProvider>
    <UserMenuProvider>
      <ExhibitionContainer {...props} />
    </UserMenuProvider>
  </UserProvider>
);
