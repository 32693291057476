import React from 'react';

import {useExhibitionContext} from './ExhibitionContext';
import SiteChrome from './SiteChrome';
import ExhibitionChrome from './ExhibitionChrome';

const SmartPageChrome = ({children}: {children?: React.ReactNode}) => {
  const exhibition = useExhibitionContext();

  if (exhibition) {
    return <ExhibitionChrome>{children}</ExhibitionChrome>;
  }

  return <SiteChrome>{children}</SiteChrome>;
};

export default SmartPageChrome;
