import React, {useMemo} from 'react';
import styled from 'styled-components';
import {Link, useLocation} from 'react-router-dom';
import {useTranslation, Trans} from 'react-i18next';

import {WHITE, FONT_BOLD, BLACK, DEVICE, LIGHT_GRAY} from '../const';
import {useExhibitionContext} from './ExhibitionContext';
import {useLogin} from '../contexts/UserContext';
import PlainButton from './PlainButton';
import Picture from './Picture';
import cutSlash from '../utils/cutSlash';
import userName from '../utils/userName';
import {useUserMenu} from '../contexts/UserMenuContext';
import UserIcon from '../assets/svg/user';
import PersonnelAvailabilityControl from './PersonnelAvailabilityControl';
import useFirestoreRecord from '../data/useFirestoreRecord';
import usePersonnelCheckUpdate from '../data/usePersonnelCheckUpdate';

interface PropsType {
  className?: string;
}

const Widget = styled.div`
  display: flex;
  color: ${({theme: {theme}}) => (theme === 'dark' ? WHITE : BLACK)};
  justify-content: flex-end;
  flex: 0 1 auto;
  flex-direction: column;
  align-items: flex-end;
  @media ${DEVICE.tablet} {
    flex-direction: row;
    align-items: flex-start;
  }
`;

const Avatar = styled(Picture)`
  flex: 0 0 73px;
  width: 73px;
  height: 73px;
  border-radius: 73px;
  background: ${LIGHT_GRAY};
  @media ${DEVICE.tablet} {
    margin-right: 20px;
  }
`;

const Content = styled.div`
  text-align: right;
  font-weight: ${FONT_BOLD};
  flex: 0 1 auto;
`;

const Username = styled(Link)`
  font-size: 22px;
  color: inherit;
  text-decoration: none;
  display: none;
  @media ${DEVICE.tablet} {
    display: block;
  }
`;

const Button = styled(PlainButton)`
  min-width: 120px;
  margin-top: 15px;
`;

const WidgetAvailabilityControl = styled(PersonnelAvailabilityControl)`
  margin: 2px 0 0;
  font-size: 12px;
  text-align: right;
`;

const UserWidget = ({className}: PropsType) => {
  const login = useLogin();
  const exhibition = useExhibitionContext();
  const [menuVisible, setMenuVisible, menuInPage] = useUserMenu();
  const {t} = useTranslation();
  const {pathname} = useLocation();
  const personnelRef = useMemo(
    () =>
      exhibition && login.user
        ? login.user.personnel?.find(
            (p) => p.path.indexOf(exhibition.ref.path) === 0,
          )
        : undefined,
    [login.user, exhibition],
  );
  const [personnel] = useFirestoreRecord(personnelRef, [personnelRef], true);
  usePersonnelCheckUpdate(personnel);

  if (exhibition == null || login.loading) {
    return null;
  }

  const name = login.user ? userName(login.user) : undefined;

  if (menuInPage) {
    return (
      <>
        <Widget
          className={className}
          onClick={() => {
            setMenuVisible(!menuVisible);
          }}>
          {login.user?.photo ? (
            <Avatar
              mode="crop"
              ratioW={1}
              ratioH={1}
              alt={name}
              picture={login.user.photo}
            />
          ) : (
            <Avatar as={UserIcon} />
          )}
        </Widget>
      </>
    );
  }

  if (login.user == null) {
    return (
      <Widget className={className}>
        <Button
          as={Link}
          to={`${cutSlash(exhibition.baseUrl)}/login?redir=${encodeURIComponent(
            pathname,
          )}`}>
          {t('common.login_register')}
        </Button>
      </Widget>
    );
  }

  const profileLink = `${cutSlash(exhibition.baseUrl)}/profile`;

  let avatar = login.user.photo ? (
    <Avatar mode="crop" alt={name} picture={login.user.photo} id="useravatar" />
  ) : (
    <Avatar as={UserIcon} />
  );
  if (login.valid) {
    avatar = <Link to={profileLink}>{avatar}</Link>;
  }

  return (
    <Widget className={className}>
      {avatar}
      <Content>
        {personnel == null ? (
          <div>
            <Trans
              i18nKey="user_widget.private_area"
              values={{name}}
              components={{
                name: login.valid ? (
                  <Username to={profileLink} />
                ) : (
                  <Username as="div" />
                ),
              }}
            />
          </div>
        ) : (
          <>
            <Username to={profileLink}>{name}</Username>
            <WidgetAvailabilityControl personnel={personnel} />
          </>
        )}

        <Button type="button" onClick={() => login.logout()}>
          {t('common.logout')}
        </Button>
      </Content>
    </Widget>
  );
};

export default UserWidget;
