import * as React from 'react';
import {BLACK} from '../../const';

function MinimizeIcon({
  fill = BLACK,
  width,
  height,
  ...props
}: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={width ?? 23}
      height={typeof width === 'number' ? width : height ?? 23}
      viewBox="0 0 23 23"
      {...props}>
      <rect fill={fill} x={0} y={17} width={23} height={5} />
    </svg>
  );
}

export default MinimizeIcon;
