import {AnalyticsExhibitionData, Exhibition} from '../types';
import getTranslatedValue from './getTranslatedValue';
import type i18next from 'i18next';

export default function getAnalyticsExhibitionData(
  exhibition: Exhibition | null,
  i18n: typeof i18next,
): AnalyticsExhibitionData {
  if (!exhibition) {
    return {};
  }

  const translatedName = getTranslatedValue(exhibition, 'name', i18n);

  const res: AnalyticsExhibitionData = {
    exhibition_id: exhibition.ref.id,
    exhibition_name: translatedName,
  };
  if (typeof window !== 'undefined') {
    res.exhibition_domain = window.location.hostname;
  }

  return res;
}
