import {Translated} from './translated';
import firebase from 'firebase/app';
import {ExhibitorData} from './exhibitor';
import {ThemeID} from './theme';
import {PublishStatus, RecordRef, URLString, VideoURL} from './common';
import {ExhibitionData} from './exhibition';
import {ChimeMeeting} from './meeting';

export enum LiveStatus {
  scheduled = 'scheduled',
  ready = 'ready',
  live = 'live',
  past = 'past',
}

export enum EventVideoSource {
  external = 'external',
  rtmp = 'rtmp',
  browser = 'browser',
}

export enum ChatType {
  none = 'none',
  embed = 'embed',
  internal = 'internal',
}

/**
 * Collection `/exhibitions/<exhibitionId>/events/<eventId>`
 */
export interface EventData
  extends Translated<{title: string; description?: string}> {
  status: PublishStatus;

  startAt: firebase.firestore.Timestamp;
  endAt: firebase.firestore.Timestamp;
  liveStart?: firebase.firestore.Timestamp;
  liveEnd?: firebase.firestore.Timestamp;
  exhibitor?: firebase.firestore.DocumentReference<ExhibitorData>;
  priority: number; // lower the priority, higher the visibility; use 0 for keynote event
  theme?: ThemeID;
  liveVideo?: VideoURL;
  video?: VideoURL;
  liveStatus: LiveStatus;
  hostName: string;
  chat?: ChatType;
  source: EventVideoSource;
  data?: {
    playbackUrl?: string;
  };
  ids?: {[key: string]: number | string};
}

export type Event = RecordRef<EventData>;

/**
 * Document `/exhibitions/<exhibitionId>/events/<eventId>/channel/data`
 */
export interface EventChannelData {
  createdAt: firebase.firestore.FieldValue | firebase.firestore.Timestamp;
  ingestEndpoint: URLString;
  streamKey: string;
  arn: string;
}

export type EventChannel = RecordRef<EventChannelData>;

/**
 * Document `/exhibitions/<exhibitionId>/events/<eventId>/channel/broadcaster`
 */
export interface EventBroadcasterData {
  startedAt: firebase.firestore.FieldValue | firebase.firestore.Timestamp;
  instanceId: string;
}

export type EventBroadcaster = RecordRef<EventBroadcasterData>;

/**
 * Document `/exhibitions/<exhibitionId>/events/<eventId>/channel/meeting`
 */
export interface EventMeetingData {
  Meeting: ChimeMeeting;
}

export type EventMeeting = RecordRef<EventMeetingData>;

export interface WatchInterval {
  f: firebase.firestore.Timestamp;
  t: firebase.firestore.Timestamp;
  s: LiveStatus;
}

/**
 * Collection `/users/<userId>/subscriptions/<subscriptionId>`
 */
export interface EventSubscriptionData {
  event: firebase.firestore.DocumentReference<EventData>;
  exhibition: firebase.firestore.DocumentReference<ExhibitionData>;
  subscribedAt: firebase.firestore.Timestamp;
  startAt: firebase.firestore.Timestamp;
  watched?: WatchInterval[];
  lastWatched?: WatchInterval;
  watchedTotal?: {
    [LiveStatus.live]?: number;
    [LiveStatus.past]?: number;
  };
}

export interface EventSubscriptionDataUpdate {
  watched?: WatchInterval[] | firebase.firestore.FieldValue;
  lastWatched?: {
    f: firebase.firestore.FieldValue;
    t: firebase.firestore.FieldValue;
    s: LiveStatus;
  };
  'lastWatched.f'?: firebase.firestore.FieldValue;
  'lastWatched.t'?: firebase.firestore.FieldValue;
  watchedTotal?: {
    [LiveStatus.live]?: number;
    [LiveStatus.past]?: number;
  };
  'watchedTotal.live'?: number;
  'watchedTotal.past'?: number;
}

export type EventSubscription = RecordRef<EventSubscriptionData>;
