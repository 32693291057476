import React, {
  useEffect,
  useReducer,
  createContext,
  useContext,
  useMemo,
  useRef,
} from 'react';
import firebase from 'firebase/app';

import NotificationBadge from '../components/NotificationBadge';
import {Exhibition} from '../types';
import {noop} from 'lodash';
import {useChatContext} from '../pages/exhibition/ChatContainer';

// tslint:disable-next-line: no-var-requires
const notificationSound = require('../assets/sounds/notification.mp3');

export enum NotificationClass {
  call = 'call',
  event = 'event',
  your_event = 'your_event',
  businesscard = 'businesscard',
  call_invite = 'call_invite',
  chat = 'chat',
}

interface NotificationDataBase {
  exhibition: string;
  type: NotificationClass;
  path?: string;
}

interface NotificationData extends NotificationDataBase {
  users?: string[];
}

interface NotificationFcmData extends NotificationDataBase {
  users?: string;
}

interface NotificationFcmType extends firebase.messaging.NotificationPayload {
  data?: NotificationFcmData;
  link?: string;
}

interface NotificationType extends firebase.messaging.NotificationPayload {
  data?: NotificationData;
  link?: string;
}

interface StateType {
  queue: NotificationType[];
  notification: NotificationType | null;
  visible: boolean;
  visibleTime: number;
}

type ActionType =
  | {action: 'add'; notification: NotificationFcmType | NotificationType}
  | {action: 'dismiss'; data: Partial<NotificationData>}
  | {action: 'insert' | 'show' | 'hide' | 'advance'};

interface NotificationCenter {
  enqueue: (notification: NotificationFcmType | NotificationType) => void;
  dismiss: (matching: Partial<NotificationData>) => void;
}

const NotificationContext = createContext<NotificationCenter>({
  enqueue: noop,
  dismiss: noop,
});

function notificationMatches(
  notification: NotificationType | null,
  matches: Partial<NotificationData>,
): boolean {
  if (!notification) {
    return false;
  }
  const {data} = notification;
  return (
    data != null &&
    (!matches.exhibition || data.exhibition === matches.exhibition) &&
    (!matches.type || data.type === matches.type) &&
    (!matches.users ||
      (data.users != null &&
        matches.users.some((u) => data.users!.includes(u))))
  );
}

const notificationTimes: {default: number} & {
  [type in NotificationClass]?: number;
} = {
  default: 5000,
  [NotificationClass.call]: 20000,
  [NotificationClass.chat]: 20000,
};

function reducer(prevState: StateType, action: ActionType): StateType {
  const newState = {...prevState};
  switch (action.action) {
    case 'add':
      const obj = {
        ...action.notification,
      };
      if (typeof obj.data?.users === 'string') {
        (obj as NotificationType).data!.users = (
          obj as NotificationFcmType
        ).data!.users!.split(',');
      }
      newState.queue = [...newState.queue, obj as NotificationType];
      break;

    case 'insert':
      newState.notification = newState.queue[0];
      newState.visible = false;
      newState.visibleTime =
        (newState.notification.data?.type != null &&
          notificationTimes[newState.notification.data!.type]) ||
        notificationTimes.default;
      break;

    case 'show':
      newState.visible = true;
      break;

    case 'hide':
      newState.visible = false;
      break;

    case 'dismiss':
      if (notificationMatches(newState.notification, action.data)) {
        newState.notification = null;
        newState.visible = false;
        newState.queue = newState.queue.slice(1);
      }
      const newQueue = newState.queue.filter(
        (n) => !notificationMatches(n, action.data),
      );
      if (newQueue.length < newState.queue.length) {
        newState.queue = newQueue;
      }
      break;

    default:
    case 'advance':
      newState.queue = newState.queue.slice(1);
      newState.notification = null;
      newState.visible = false;
      break;
  }
  return newState;
}

const NotificationListener = ({
  children,
  exhibition,
}: {
  children: React.ReactNode;
  exhibition: Exhibition;
}) => {
  const [{queue, notification, visible, visibleTime}, dispatch] = useReducer<
    typeof reducer
  >(reducer, {queue: [], notification: null, visible: false, visibleTime: 0});
  const audio = useRef<HTMLAudioElement>(null);
  const {upsertChat} = useChatContext();

  const notifObject = useMemo(
    () => ({
      enqueue: (notif: NotificationFcmType | NotificationType) => {
        dispatch({
          action: 'add',
          notification: notif,
        });
      },
      dismiss: (matching: Partial<NotificationData>) => {
        dispatch({
          action: 'dismiss',
          data: matching,
        });
      },
    }),
    [],
  );

  useEffect(() => {
    if (!firebase.messaging.isSupported()) {
      return;
    }

    const msg = firebase.messaging();
    return msg.onMessage((message: firebase.messaging.MessagePayload) => {
      if (
        !message.notification ||
        (message.data != null && message.data.exhibition !== exhibition.ref.id)
      ) {
        return;
      }
      dispatch({
        action: 'add',
        notification: {
          ...message.notification,
          data: message.data as unknown as NotificationFcmData,
          link: message.fcmOptions?.link,
        },
      });
    });
  }, [exhibition]);

  useEffect(() => {
    if (notification != null || queue.length === 0) {
      return;
    }

    dispatch({action: 'insert'});
  }, [notification, queue]);

  useEffect(() => {
    if (!notification) {
      return;
    }
    let timer: NodeJS.Timeout | undefined;

    dispatch({action: 'show'});
    timer = setTimeout(() => {
      dispatch({action: 'hide'});
      timer = setTimeout(() => {
        dispatch({action: 'advance'});
        timer = undefined;
      }, 2000);
    }, visibleTime);

    return () => {
      if (timer != null) {
        clearTimeout(timer);
      }
    };
  }, [notification, visibleTime, audio]);

  useEffect(() => {
    if (!visible) {
      return;
    }

    function play() {
      if (audio.current != null) {
        try {
          audio.current.currentTime = 0;
          audio.current.play();
        } catch (e) {
          console.error(e);
        }
      }
    }

    play();
    const timer: NodeJS.Timeout | undefined = setInterval(play, 5500);

    return () => {
      clearTimeout(timer);
      audio.current?.pause();
    };
  }, [visible, audio]);

  const onClick = useMemo<
    React.MouseEventHandler<HTMLButtonElement> | undefined
  >(() => {
    if (
      notification?.data?.type === 'chat' &&
      notification!.data!.path != null
    ) {
      return (e) => {
        e.preventDefault();
        upsertChat(notification!.data!.path!, {open: true});
      };
    }
    return undefined;
  }, [notification, upsertChat]);

  return (
    <NotificationContext.Provider value={notifObject}>
      {children}
      <audio ref={audio} src={notificationSound} preload="auto" />
      {notification && (
        <NotificationBadge
          visible={visible}
          {...notification}
          onClick={onClick}
        />
      )}
    </NotificationContext.Provider>
  );
};

export const useNotifications = (): NotificationCenter =>
  useContext(NotificationContext);

export default NotificationListener;
