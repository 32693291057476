import {useEffect, DependencyList} from 'react';
import firebase from 'firebase/app';
import useFirestoreReducer, {ReducerAction} from './useFirestoreReducer';

const useFirestoreSnap = <D, R>(
  ref:
    | firebase.firestore.DocumentReference<D>
    | undefined
    | null
    | string
    | (() => firebase.firestore.DocumentReference<D> | undefined | null),
  transform: (snap: firebase.firestore.DocumentSnapshot<D>) => R | undefined,
  deps: DependencyList,
  live: boolean = false,
): [R | undefined, boolean] => {
  const [result, loading, dispatch] = useFirestoreReducer<R>();

  useEffect(() => {
    const r =
      typeof ref === 'function'
        ? ref()
        : typeof ref === 'string'
        ? (firebase
            .firestore()
            .doc(ref) as firebase.firestore.DocumentReference<D>)
        : ref;
    if (!r) {
      dispatch({action: ReducerAction.inexistent});
      return;
    }
    let cancel: (() => void) | undefined;
    if (live) {
      dispatch({action: ReducerAction.loading});
      cancel = r.onSnapshot((d) => {
        if (d.exists) {
          dispatch({action: ReducerAction.loaded, value: transform(d)});
        } else {
          dispatch({action: ReducerAction.inexistent});
        }
      });
    } else {
      dispatch({action: ReducerAction.loading});
      (async () => {
        const d = await r.get();
        if (d.exists) {
          dispatch({action: ReducerAction.loaded, value: transform(d)});
        } else {
          dispatch({action: ReducerAction.inexistent});
        }
      })();
    }
    return cancel;
  }, deps);

  return [result, loading];
};

export default useFirestoreSnap;
