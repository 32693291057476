import firebase from 'firebase/app';

export default function reportSnapshotError(
  r:
    | string
    | firebase.firestore.DocumentReference
    | firebase.firestore.CollectionReference,
) {
  return (e: Error) => {
    console.error(
      `Error while loading ${typeof r === 'string' ? r : r.path}: ${e}`,
    );
  };
}
