import React, {useCallback, useEffect, useMemo, useState} from 'react';
import firebase from 'firebase/app';
import styled from 'styled-components';
import useFirestoreDoc from '../data/useFirestoreDoc';

import {
  ChatMessage,
  ChatStatus,
  Exhibition,
  MeetingData,
  PersonnelData,
  User,
} from '../types';
import userName from '../utils/userName';
import extractUserPublicData from '../utils/extractUserPublicData';
import FirestoreChat from './FirestoreChat';
import {useChatContext} from '../pages/exhibition/ChatContainer';
import FloatingChatWidget from './FloatingChatWidget';
import {
  NotificationClass,
  useNotifications,
} from '../contexts/NotificationListener';
import {LIGHTER_GRAY, MILD_GRAY, personnelStatusColor} from '../const';
import {useLogin} from '../contexts/UserContext';

interface PropsType extends ChatStatus {
  path: string;
  exhibition: Exhibition;
  user: User;
}

const ChatArea = styled(FirestoreChat)<{hidden?: boolean}>`
  ${({hidden}) => (hidden ? 'display: none;' : '')}
`;

const PersistentChat = ({path, open, exhibition, user}: PropsType) => {
  const login = useLogin();
  const {upsertChat} = useChatContext();
  const [blink, setBlink] = useState<boolean>(false);
  const meetingRef = useMemo(
    () =>
      firebase
        .firestore()
        .doc(path) as firebase.firestore.DocumentReference<MeetingData>,
    [path],
  );
  const chatRef = useMemo(
    () =>
      meetingRef.collection(
        'chat',
      ) as firebase.firestore.CollectionReference<ChatMessage>,
    [meetingRef],
  );
  const personnelRef = useMemo(
    () =>
      meetingRef.parent!
        .parent! as firebase.firestore.DocumentReference<PersonnelData>,
    [meetingRef],
  );
  const [personnel] = useFirestoreDoc<PersonnelData>(
    personnelRef,
    [personnelRef],
    true,
  );
  const isPersonnel = personnel?.user?.id === login?.user?.ref?.id;
  const [meeting] = useFirestoreDoc<MeetingData>(
    meetingRef,
    [meetingRef],
    true,
  );
  const users = useMemo(() => {
    if (!meeting || !personnel || !user) {
      return undefined;
    }
    const res = {...meeting.userData};
    res[personnel.user.id] = extractUserPublicData(personnel);
    delete res[user.ref.id];
    return res;
  }, [meeting, user, personnel]);
  const notifications = useNotifications();

  useEffect(() => {
    if (!meeting) {
      return;
    }
    notifications.dismiss({
      exhibition: exhibition.ref.id,
      type: NotificationClass.chat,
      users: meeting.users.map(({id}) => id),
    });
  }, [meeting]);

  const handleNewMessage = useCallback(() => {
    setBlink(true);
  }, []);

  useEffect(() => {
    setBlink(false);
  }, [open]);

  return (
    <FloatingChatWidget
      onLabelClick={() => upsertChat(path, {open: true})}
      onMinimizeClick={open ? () => upsertChat(path, {open: false}) : undefined}
      onCloseClick={() => upsertChat(path, {open: false, hidden: true})}
      label={
        users
          ? Object.values(users)
              .map((u) => userName(u))
              .join(', ')
          : '–'
      }
      titleColor={
        personnel && !isPersonnel
          ? personnelStatusColor[personnel.status]
          : MILD_GRAY
      }
      titleBackground={LIGHTER_GRAY}
      blink={blink}>
      <ChatArea
        exhibition={exhibition}
        user={user}
        position={chatRef}
        msgSound={true}
        onNewMessage={open ? undefined : handleNewMessage}
        hidden={!open}
        reportLast={true}
      />
    </FloatingChatWidget>
  );
};

export default PersistentChat;
